import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from "src/environments/environment";
@Injectable({ providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  baseUrl = environment.baseUrl;

  public SignUp(formData: FormData) {
    return this.http.post(this.baseUrl + "user/v1/signup", formData);
  }
}
