import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CreateVerifiedSherpaAccountService } from './create-verified-sherpa-account.service';
import { NotificationService } from '../core/services/notification.service';
import { VerifiedUserService } from '../main/verified-user/verified-user.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import * as _moment from 'moment';
import * as CryptoJS from "crypto-js";
import { environment } from 'src/environments/environment';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CATEGORY, Experience } from '../utils/constant';

const moment = _moment;

@Component({
  selector: 'app-create-verified-sherpa-account',
  templateUrl: './create-verified-sherpa-account.component.html'
})
export class CreateVerifiedSherpaAccountComponent implements OnInit {
  maxDate!: Date;
  Experience: any[] = Experience;
  CATEGORY: any[] = CATEGORY;
  dateNowISO: any;
  createSherpaForm!: FormGroup;
  _id: any;
  public submitted = false;

  // checked: any = "agreed";
  token: any;
  decode: any;
  queryToken: any;

  constructor(private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly createVerifiedSherpaAccountService: CreateVerifiedSherpaAccountService,
    private readonly mainNotify: NotificationService,
    private readonly verifiedUserService: VerifiedUserService,
    private readonly ngxLoader: NgxUiLoaderService
  ) {
    this.queryToken = this.activatedRoute.snapshot.queryParams['token']
    const currentMonth = new Date();
    this.maxDate = new Date(currentMonth);
  }
  
  ngOnInit(): void {   
    this.Checkquerytoken();
    this.createSherpaForm = new FormGroup({
      FirstName: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(20), Validators.pattern('^[a-zA-Z][a-zA-Z ]+( ?[a-zA-Z]+)*$')]),
      LastName: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(20), Validators.pattern('^[a-zA-Z][a-zA-Z ]+( ?[a-zA-Z]+)*$')]),
      DateOfBirth: new FormControl('', [Validators.required]),
      PhoneNumber: new FormControl('', [Validators.required, Validators.minLength(12)]),
      vEmailAddress: new FormControl('', [Validators.required, Validators.email, Validators.pattern('^[A-z0-9._%+-]+@[A-z0-9]+[.]{1,1}[A-z]{2,4}$')]),
      Address: new FormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(50)]),
      Unit: new FormControl(''),
      ActivityPreference: new FormControl('', [Validators.required]),
      ActivityExpertise: new FormControl('', [Validators.required]),
    });
  }

  Apireponse: any
  dateDoB: any
  showProfileData() {
    this.verifiedUserService.getBecomeSherpaProfileView(this.queryToken)
      .subscribe(async (result: any) => {
        if (result.code === 200) {
          const data: any = await this.Decrypt(result.data);
          this.dateDoB = data.data.DateOfBirth;
          this.createSherpaForm.patchValue({
            FirstName: data['data']['FirstName'],
            LastName: data['data']['LastName'],
            DateOfBirth: this.dateDoB,
            PhoneNumber: data['data']['PhoneNumber'],
            vEmailAddress: data['data']['Email'],
            Address: data['data']['Address'],
            Unit: data?.data['Unit'],
          })
        } else {
          this.router.navigate(['/']);
        }
      })
  }

  async Decrypt(encryptedData: any) {
    return new Promise(async (resolve, reject) => {
      if (encryptedData) {
        const iv = CryptoJS.enc.Base64.parse(environment.crypto_iv);
        const decryptedText = CryptoJS.AES.decrypt(encryptedData, environment.crypto_key).toString(CryptoJS.enc.Utf8);
        const decrypted = JSON.parse(decryptedText);
        if (decrypted) {
          return resolve({ status: true, data: decrypted });
        } else {
          return resolve({ status: false, data: {} });
        }
      } else {
        return resolve({ status: false, data: {} });
      }
    });
  }

  data: any;
  tokenfromApi: any;
  CreateSherpaProcess() {
    this.submitted = true;
    if (this.createSherpaForm.invalid) {
      return;
    }
    this.ngxLoader.start();
    const tokenfromApi = this.activatedRoute.snapshot.queryParams['token']
    // const data = this.checked;
    this.createSherpaForm.value['IsIronCladContratAgreed'] = 'agreed'
    this.createVerifiedSherpaAccountService.CreateVerifiedSherpa(this.createSherpaForm.value, tokenfromApi).subscribe((result: any) => {
    this.ngxLoader.start();

      if (result.code === 200) {
        this.ngxLoader.stop();
        this.router.navigate(['/']);
        this.mainNotify.showSuccess(result.message, '');
      }
      else if(result.code === 403){
        this.ngxLoader.stop();
        this.mainNotify.showError(result.message,'');
      }
      else if(result.code === 401){
        this.ngxLoader.stop();
        this.mainNotify.showError(result.message,'');
      }
      else if(result.code === 503){
        this.ngxLoader.stop();
        this.mainNotify.showError(result.message,'');
      }
      else {
        this.mainNotify.showError('Something Went Wrong...', '');
      }
    });
  }

  mydate: any = new Date();
  valueofDOBChanged(event: MatDatepickerInputEvent<Date>) {

    this.mydate = event.value;
    const date = moment(this.mydate, "YYYY-MM-DD").format();
    this.dateNowISO = date.split('T')[0];
  }

  Checkquerytoken() {
    this.createVerifiedSherpaAccountService.CheckVerifiedToken(this.queryToken).subscribe((result: any) => {
      if (result.code === 200) {
        this.showProfileData();
      }
      else if(result.code === 401){
        this.mainNotify.showError(result.message,'');
      } 
      else {
        this.router.navigate(['/']);
      }
    })
  }

}
