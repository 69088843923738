import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  showInfo(arg0: string, arg1: string) {
      throw new Error('Method not implemented.');
  }

  constructor(private readonly toastr: ToastrService) { }

  showSuccess(title: any, message: any) {
    this.toastr.success(message, title, {
      timeOut: environment.dis_alert_timeout,
      progressBar: true
    });
  }
  

  showError(title: any, message: any) {
    this.toastr.error(message, title, {
      timeOut: environment.dis_alert_timeout,
      progressBar: true
    });
  }
  
}
