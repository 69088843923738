import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { TransactionHistoryService } from './transaction-history.service';
import { DatePipe } from '@angular/common';
import * as _ from 'lodash';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { PAYMENT_STATUS, REASON } from 'src/app/utils/constant';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-transaction-history',
  templateUrl: './transaction-history.component.html',
  providers: [DatePipe],
})
export class TransactionHistoryComponent implements OnInit {
  @Input() userId: any;
  @Input() verifiedUserId: any;
  page = 1;
  limit = 10;
  total = 0;
  dataSource = [];
  item: any;
  refundForm!: FormGroup;
  PAYMENT_STATUS = PAYMENT_STATUS;
  amountRefund = '';
  reasonSelected = [
    { label: 'None', value: '' },
    { label: 'Duplicate', value: REASON.DUPLICATE },
    { label: 'Fraudulent', value: REASON.FRAUDULENT },
    { label: 'Requested by cutomer', value: REASON.REQUEST_BY_CUSTOMER },
  ];
  displayedColumns: string[] = ['Amount', 'PaymentMethod', 'Description', 'Service', 'Date', 'Status', 'Refunded'];
  @ViewChild('modalDetail', { static: true }) modalDetail: TemplateRef<any> | undefined;
  @ViewChild('modalRefund', { static: true }) modalRefund: TemplateRef<any> | undefined;
  dialogRefRefund: any;
  constructor(private modal: NgbModal, private servicesService: TransactionHistoryService) {}

  ngOnInit(): void {
    this.getOrderPayments();
    this.refundForm = new FormGroup({
      Amount: new FormControl(0, [Validators.required]),
      reason: new FormControl(null),
    });
    // this.modal.open(this.modalRefund, { size: 'md' });
  }
  validateDecimal(event: any) {
    const decimalPlaces = 2;
    const regex = new RegExp(`^\\d+(\\.\\d{0,${decimalPlaces}})?$`);
    if (!_.isEmpty(event.target.value) && !regex.test(event.target.value)) {
      this.refundForm.controls['Amount'].setValue(parseFloat(event.target.value).toFixed(decimalPlaces));
    } else if (Number(event.target.value) > (this.item.TotalAmount - this.item.RefundedAmount) / 100) {
      this.refundForm.controls['Amount'].setValue((this.item.TotalAmount - this.item.RefundedAmount) / 100);
    }
    this.amountRefund = Number(event.target.value).toFixed(2);
  }

  onBlur(event: any) {
    this.refundForm.controls['Amount'].setValue(Number(event.target.value).toFixed(2));
  }

  setShowModalDetail(item: any) {
    this.item = item;
    this.refundForm.patchValue({
      Amount: (item.TotalAmount - item.RefundedAmount) / 100,
      reason: '',
    });
    this.amountRefund = ((item.TotalAmount - item.RefundedAmount) / 100).toFixed(2);
    this.modal.open(this.modalDetail, { size: 'lg' });
  }

  setShowModalRefund() {
    this.dialogRefRefund = this.modal.open(this.modalRefund, { size: 'md' });
  }

  getFullName(user: any) {
    return _.trim(`${user?.FirstName || ''} ${user?.LastName || ''}`);
  }

  formatTime(time: any, type?: any) {
    return moment(time).format(type || 'YYYY-MM-DD');
  }

  submitRefund() {
    const payload = {
      amount: Number(this.refundForm.value.Amount) * 100,
      reason: this.refundForm.value.reason || undefined,
      OrderPaymentId: this.item._id,
    };
    this.servicesService.refundPayment(payload).subscribe((res: any) => {
      if (res.code == 200) {
        if (this.dialogRefRefund) {
          this.servicesService.cleanOrderPayments();
          this.handlePageChange(1);
          this.dialogRefRefund.close();
        }
      }
    });
  }

  handlePageChange(value: any) {
    this.page = value;
    this.getOrderPayments();
  }

  getCustomer() {
    const transaction = this.item.Transactions.find((trans: any) => trans.PaymentStatus === PAYMENT_STATUS.SUCCEEDED);
    const customer = transaction?.User || transaction.VerifiedUser || transaction.Admin;
    return this.getFullName(customer);
  }

  orderTransactions(transactions: any) {
    return _.orderBy(transactions, 'createdAt', 'desc');
  }

  getTransactionReason(transaction: any) {
    let reason = '';
    if (transaction.reason === REASON.DUPLICATE) {
      reason = 'due to duplicate charge';
    } else if (transaction.reason === REASON.FRAUDULENT) {
      reason = 'due to fraud';
    } else if (transaction.reason === REASON.REQUEST_BY_CUSTOMER) {
      reason = 'due to customer request';
    }
    if (transaction.PaymentStatus === PAYMENT_STATUS.SUCCEEDED) {
      return 'Payment succeeded';
    } else if (transaction.PaymentStatus === PAYMENT_STATUS.START) {
      return 'Payment started';
    } else if (transaction.PaymentStatus === PAYMENT_STATUS.REFUNDED) {
      return `Successfully refunded ${reason}, It may take a few days for the money to reach the customer's bank account.`;
    } else if (transaction.PaymentStatus === PAYMENT_STATUS.PARTIAL_REFUNDED) {
      return `Successfully refunded US$${(transaction.Amount / 100).toFixed(
        2,
      )} ${reason}, It may take a few days for the money to reach the customer's bank account.`;
    } else {
      return '';
    }
  }

  getOrderPayments() {
    const params = {
      skip: (this.page - 1) * this.limit,
      limit: this.limit,
      userId: this.userId,
      verifiedUserId: this.verifiedUserId,
    };
    this.servicesService.getOrderPayments(params, (res: any) => {
      if (res.data) {
        this.dataSource = res?.data?.edges || [];
        this.total = res?.data?.pageInfo?.[0]?.count || 0;
        if (this.item) {
          const newItem = res?.data?.edges.find((item: any) => item._id === this.item._id);
          if (newItem) {
            this.item = newItem;
            this.refundForm.patchValue({
              Amount: (newItem.TotalAmount - newItem.RefundedAmount) / 100,
              reason: '',
            });
            this.amountRefund = ((newItem.TotalAmount - newItem.RefundedAmount) / 100).toFixed(2);
          } else {
            this.item = null;
            this.modal.dismissAll();
          }
        }
      }
    });
  }
}
