import * as _ from 'lodash';
import * as moment from 'moment';
import { orderStatus } from './constant';
import { reviewType } from './constant';

export const createQuery = (params: any) => {
  const query = params
    ? Object.keys(_.omitBy(params, (value: any) => !value))
        .map(key => `${key}=${params[key]}`)
        .join('&')
    : '';
  return query;
};

export const getListTime = (index = 0) => {
  const listTime: any = [];
  for (let i = 0 + index; i < 48 + index; i++) {
    const mDay = moment()
      .startOf('days')
      .add(i / 2, 'hours');
    listTime.push({ value: i / 2, viewValue: mDay.format('hh:mm A') });
  }
  return listTime;
};

export const floorReview = (reviews: any, type: any = reviewType.ATHLETE) => {
  const athleteReviews = _.filter(reviews, (r: any) => r.Type === type);
  const rating = !_.isEmpty(athleteReviews) ? _.floor(_.mean(_.map(athleteReviews, 'Rating')), 1) : null;
  const number = _.size(_.filter(athleteReviews, (r: any) => r.Type === type));
  return { rating, number };
};

export const formatTimeRequest = (date: any) => {
  const minutes = moment().diff(moment(date), 'minutes');
  const hours = moment().diff(moment(date), 'hours');
  const days = moment().diff(moment(date), 'days');
  if (days > 5) {
    return moment(date).format('DD/MM/YYYY');
  } else if (days > 0) {
    return `${days} days ago`;
  } else if (hours > 0) {
    return `${hours} hours ago`;
  } else if (minutes > 0) {
    return `${minutes} minutes ago`;
  } else {
    return 'Now';
  }
};

export const getOrderStatus = ({ Status, StartTime }: any) => {
  const { REQUESTED, ACCEPTED, EXPIRED, CONFIRMED, COMPLETED } = orderStatus;
  let resultStatus = Status;
  if ([REQUESTED, ACCEPTED].includes(resultStatus) && StartTime && moment(StartTime) <= moment()) {
    resultStatus = EXPIRED;
  } else if (CONFIRMED === Status && StartTime && moment(StartTime) <= moment()) {
    resultStatus = COMPLETED;
  }
  return resultStatus;
};
