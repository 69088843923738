<div class="signup container-fluid d-flex flex-column align-items-sm-center h-100 p-0 bg-white text-base">
    <div class="row w-100 h-100 m-auto">
      <div name="left-side" id="left-side" class="col-lg-5 p-0 flex justify-center items-center section-box">
        <div class="w-[500px] flex flex-col items-center">
          <img class="mb-6" src="./assets/image/icons/verify100.svg" alt="Go-Sherpa-icon" />
          <p class="text-3xl text-[#58c0a8]">{{ 'EMAIL_VERIFICATION_SUCCESS' | translate }}</p>
          <p class="mt-2">{{ 'REGISTRATION_NOTE_1' | translate }}</p>
          <p class="mt-2">{{ 'REGISTRATION_NOTE_2' | translate }}</p>
          <a href="https://apps.apple.com/us/app/go-with-sherpa/id6446352532" target="_blank">
            <img class="mt-2" src="./assets/image/icons/app-store.svg" alt="apple-app-store-logo">
          </a>
        </div>
      </div>
      <div name="right-side text-sm" id="right-side" class="col-lg-7 d-none d-lg-block p-0">
        <div name="section-side-solid-box" id="section-side-solid-box" class="section-side-login-box h-100"></div>
      </div>
    </div>
  </div>
  