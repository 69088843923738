import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from './environments/environment';
import * as Sentry from "@sentry/angular-ivy";
import { AppModule } from "./app/app.module";

Sentry.init({
  dsn: "https://3b83075e199e45898aa01efc28e48f65@o4505000304574464.ingest.sentry.io/4505000309096448",
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: ["https://tekdev.teksun.com/projects/gowithsherpa/"],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],
  tracesSampleRate: 0.5,
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(success => console.log(`Bootstrap success`))
  .catch(err => console.error(err));
