import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ServicesService } from './services.service';
import { DatePipe } from '@angular/common';
import * as _ from 'lodash';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

@Component({
  selector: 'app-services-component',
  templateUrl: './services.component.html',
  providers: [DatePipe],
})
export class ServicesComponent implements OnInit {
  @Input() userId: any;
  @Input() verifiedUserId: any;
  page = 1;
  limit = 10;
  total = 0;
  dataSource = [];
  item: any;
  displayedColumns: string[] = ['_id', 'FirstName', 'CategoryName', 'ServiceName', 'Amount', 'Status'];
  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any> | undefined;
  constructor(private modal: NgbModal, private servicesService: ServicesService) {}

  ngOnInit(): void {
    this.getServiceList();
  }

  setShowDetail(elm: any) {
    this.item = elm;
    this.modal.open(this.modalContent, { size: 'lg' });
  }

  getFullName(user: any) {
    return _.trim(`${user?.FirstName || ''} ${user?.LastName || ''}`);
  }

  formatTime(time: any) {
    return moment(time).format('YYYY-MM-DD');
  }

  handlePageChange(value: any) {
    this.page = value;
    this.getServiceList();
  }

  getServiceList() {
    const params = {
      skip: (this.page - 1) * this.limit,
      limit: this.limit,
      VerifiedUserId: this.verifiedUserId,
    };
    this.servicesService.getServiceList(params, (res: any) => {
      if (res.data) {
        this.dataSource = res?.data?.edges || [];
        this.total = res?.data?.pageInfo?.[0]?.count || 0;
      }
    });
  }
}
