<div class="flex" *ngIf="getOrderStatus(userOrder) as status">
  <div *ngIf="status === orderStatus.REQUESTED" class="bg-purple-50 text-purple-600 text-xs px-2 py-1 rounded-sm">
    {{ status }}
  </div>
  <div *ngIf="status === orderStatus.REJECTED" class="bg-red-50 text-red-600 text-xs px-2 py-1 rounded-sm">
    {{ status }}
  </div>
  <div *ngIf="status === orderStatus.ACCEPTED" class="bg-yellow-50 text-yellow-600 text-xs px-2 py-1 rounded-sm">
    {{ status }}
  </div>
  <div *ngIf="status === orderStatus.CONFIRMED" class="bg-green-50 text-green-600 text-xs px-2 py-1 rounded-sm">
    {{ status }}
  </div>
  <div *ngIf="status === orderStatus.COMPLETED" class="bg-blue-50 text-blue-600 text-xs px-2 py-1 rounded-sm">
    {{ status }}
  </div>
  <div *ngIf="status === orderStatus.CANCELED" class="bg-gray-50 text-gray-600 text-xs px-2 py-1 rounded-sm">
    {{ status }}
  </div>
  <div *ngIf="status === orderStatus.EXPIRED" class="bg-orange-50 text-orange-600 text-xs px-2 py-1 rounded-sm">
    {{ status }}
  </div>
</div>