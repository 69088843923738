<footer class="footer">
    <div class="container">
      <div class="row">
        <div class="">
          <div class="footer-link-wrapper">
            <ul class="footer-menu-space-between">
              <li>
                <p href="#!" title="">Copyright 2023 Run Sherpa,</p>
              </li>
              <li>
                <a href="https://vault.pactsafe.io/s/55e402f4-db1c-4254-9913-3d2c28b9990b/legal.html?g=40941#template-oyjnztv-m" title="Privacy Policy" target="_blank">Privacy Policy</a>
              </li>              
              <li>
                <a href="https://vault.pactsafe.io/s/55e402f4-db1c-4254-9913-3d2c28b9990b/legal.html?g=40941#template-wojx6zzez" title="Terms of Service" target="_blank">Terms of Service</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </footer>
