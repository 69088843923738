import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ConversationsService } from './conversations.service';
import { DatePipe } from '@angular/common';
import * as _ from 'lodash';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

@Component({
  selector: 'app-conversations',
  templateUrl: './conversations.component.html',
  providers: [DatePipe],
})
export class ConversationsComponent implements OnInit {
  @Input() userId: any;
  @Input() verifiedUserId: any;
  page = 1;
  limit = 10;
  total = 0;
  dataSource = [];
  conversationId: any;
  displayedColumns: string[] = ['_id', 'Order', 'VerifiedUser', 'Service', 'createdAt'];
  @ViewChild('modalMessage', { static: true }) modalMessage: TemplateRef<any> | undefined;
  constructor(private modal: NgbModal, private ViewUserManagement: ConversationsService) {}

  ngOnInit(): void {
    this.getConversations();
  }

  setShowModalMessage(conversation: any) {
    this.conversationId = conversation._id;
    this.modal.open(this.modalMessage, { size: 'lg' });
  }

  getFullName(user: any) {
    return _.trim(`${user?.FirstName || ''} ${user?.LastName || ''}`);
  }

  formatTime(time: any) {
    return moment(time).format('YYYY-MM-DD');
  }

  handlePageChange(value: any) {
    this.page = value;
    this.getConversations();
  }

  getConversations() {
    const params = { skip: (this.page - 1) * this.limit, limit: this.limit, userId: this.userId, verifiedUserId: this.verifiedUserId };
    this.ViewUserManagement.getConversations(params, (response: any) => {
      if (response.data) {
        this.dataSource = response?.data?.edges || [];
        this.total = response?.data?.pageInfo?.[0]?.count || 0;
      }
    });
  }
}
