<div class="flex-1">
  <h2 class="mb-2">{{ (user?.AccountType === accountType.VERIFIED_USER ? 'SHERPA_PROFILE' : 'ATHLETE_PROFILE') | translate }}</h2>
  <div>
    <div class="border-b !border-Border sm:flex items-center py-3">
      <div class="flex items-center sm:w-56">
        <mat-icon svgIcon="boxUser" class="!w-5 !h-5 text-gray-500"></mat-icon>
        <label class="ml-2 text-gray-500">{{ 'FULL_NAME' | translate }}</label>
      </div>
      <div class="ml-7 py-0.5 font-bold">{{ getFullName() || '-' }}</div>
    </div>
    <div class="border-b !border-Border sm:flex items-center py-3">
      <div class="flex items-center sm:w-56">
        <mat-icon svgIcon="calendarOutline" class="!w-5 !h-5 text-gray-500"></mat-icon>
        <label class="ml-2 text-gray-500">{{ 'DATE_OF_BIRTH' | translate }}</label>
      </div>
      <div class="ml-7 py-0.5">{{ user.DateOfBirth || '-' }}</div>
    </div>
    <div class="border-b !border-Border sm:flex items-center py-3">
      <div class="flex items-center sm:w-56">
        <mat-icon svgIcon="phone" class="!w-5 !h-5 text-gray-500"></mat-icon>
        <label class="ml-2 text-gray-500">{{ 'PHONE' | translate }}</label>
      </div>
      <div class="ml-7 py-0.5">{{ user.PhoneNumber || '-' }}</div>
    </div>
    <div class="border-b !border-Border sm:flex items-center py-3">
      <div class="flex items-center sm:w-56">
        <mat-icon svgIcon="mail" class="!w-5 !h-5 text-gray-500"></mat-icon>
        <label class="ml-2 text-gray-500">{{ 'EMAIL' | translate }}</label>
      </div>
      <div class="ml-7 py-0.5">{{ user.Email || '-' }}</div>
    </div>
    <div class="border-b !border-Border sm:flex items-center py-3">
      <div class="flex items-center sm:w-56">
        <mat-icon svgIcon="locationOutline" class="!w-5 !h-5 text-gray-500"></mat-icon>
        <label class="ml-2 text-gray-500">{{ 'ADDRESS' | translate }}</label>
      </div>
      <div class="ml-7 py-0.5">{{ user.Address || '-' }}</div>
    </div>
    <div class="border-b !border-Border sm:flex items-center py-3" *ngIf="user?.AccountType === accountType.VERIFIED_USER">
      <div class="flex items-center sm:w-56">
        <mat-icon svgIcon="running" class="!w-5 !h-5 text-gray-500"></mat-icon>
        <label class="ml-2 text-gray-500">{{ 'ACTIVITY_PREFERENCE' | translate }}</label>
      </div>
      <div class="ml-7 py-0.5">{{ user.ActivityPreference || '-' }}</div>
    </div>
    <div class="border-b !border-Border sm:flex items-center py-3" *ngIf="user?.AccountType === accountType.VERIFIED_USER">
      <div class="flex items-center sm:w-56">
        <mat-icon svgIcon="char" class="!w-5 !h-5 text-gray-500"></mat-icon>
        <label class="ml-2 text-gray-500">{{ 'ACTIVITY_EXPERIENCE' | translate }}</label>
      </div>
      <div class="ml-7 py-0.5">{{ user.ActivityExpertise || '-' }}</div>
    </div>
    <div class="sm:flex items-center py-3">
      <div class="flex items-center sm:w-56">
        <mat-icon svgIcon="sherpa" class="!w-5 !h-5 text-gray-500"></mat-icon>
        <label class="ml-2 text-gray-500">{{ 'ACCOUNT' | translate }}</label>
      </div>
      <div class="ml-7 py-0.5 text-red-500">
        <span>{{ (user.Status === 1 ? 'ACTIVE' : 'INACTIVE') | translate }}</span>
      </div>
    </div>
  </div>
</div>
