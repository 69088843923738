<div class="flex-1">
  <div class="flex-1 bg-white border border-[#D3D3D3] rounded-lg overflow-hidden">
    <table mat-table [dataSource]="dataSource" matSort aria-describedby="mytable">
      <!-- No Column -->
      <ng-container matColumnDef="_id">
        <th mat-header-cell *matHeaderCellDef id="srNoHeader" class="w-20">{{ 'NO' | translate }}</th>
        <td mat-cell *matCellDef="let element; let i = index">{{ (page - 1) * limit + i + 1 }}</td>
      </ng-container>

      <!-- {{ 'SHERPA_NAME' | translate }} Column -->
      <ng-container matColumnDef="FirstName">
        <th mat-header-cell *matHeaderCellDef>{{ 'SHERPA_NAME' | translate }}</th>
        <td mat-cell *matCellDef="let element">{{ element?.VerifiedUser?.FirstName }} {{ element?.VerifiedUser?.LastName }}</td>
      </ng-container>

      <!-- Service Name Column -->
      <ng-container matColumnDef="CategoryName">
        <th mat-header-cell *matHeaderCellDef class="hidden md:table-cell">{{ 'CATEGORY' | translate }}</th>
        <td mat-cell *matCellDef="let element" class="hidden md:table-cell">{{ element?.Category?.Name }}</td>
      </ng-container>

      <!-- Service Name Column -->
      <ng-container matColumnDef="ServiceName">
        <th mat-header-cell *matHeaderCellDef class="hidden md:table-cell">{{ 'SERVICE_NAME' | translate }}</th>
        <td mat-cell *matCellDef="let element" class="hidden md:table-cell">{{ element.Name }}</td>
      </ng-container>

      <!-- Amount Column -->
      <ng-container matColumnDef="Amount">
        <th mat-header-cell *matHeaderCellDef class="hidden lg:table-cell">{{ 'AMOUNT' | translate }}</th>
        <td mat-cell *matCellDef="let element" class="hidden lg:table-cell">${{ (element.Amount / 100).toFixed(2) }}</td>
      </ng-container>

      <!-- Status Column -->
      <ng-container matColumnDef="Status">
        <th mat-header-cell *matHeaderCellDef class="w-20">{{ 'STATUS' | translate }}</th>
        <td mat-cell *matCellDef="let element">
          <span *ngIf="element.Status === 1" class="bg-LightSecondary text-ActiveSecondary px-3 py-1.5 rounded">{{ 'ACTIVE' | translate }}</span>
          <span *ngIf="element.Status !== 1" class="bg-gray-50 px-3 py-1.5 rounded">{{ 'INACTIVE' | translate }}</span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns" class="bg-[#E2E2E2]"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        class="hover:bg-BackgroundHover cursor-pointer"
        (click)="setShowDetail(row)"
      ></tr>
    </table>
    <div class="bg-white w-full h-72 flex flex-col items-center justify-center" *ngIf="dataSource.length === 0">
      <mat-icon svgIcon="runman" class="!h-[60px] !w-[60px]"></mat-icon>
      <p class="text-gray-500 text-xl font-bold m-0 mt-3">{{ 'SERVICE_EMPTY' | translate }}</p>
      <p class="text-gray-500 text-base m-0 mt-1">{{ 'SERVICE_EMPTY_NOTE' | translate }}</p>
    </div>
  </div>

  <div class="ngb-pagination" *ngIf="dataSource.length > 0">
    <ngb-pagination
      #paginator
      id="paginator"
      [collectionSize]="total"
      [pageSize]="limit"
      [maxSize]="2"
      [rotate]="true"
      [(page)]="page"
      [boundaryLinks]="true"
      (pageChange)="handlePageChange($event)"
      class="d-flex justify-content-end pagination-rounded-flat pagination-success custom-pagination ms-4"
    >
      <ng-template ngbPaginationPrevious>
        <span>{{ 'PREVIOUS' | translate }}</span>
      </ng-template>
      <ng-template ngbPaginationNext>
        <span>{{ 'NEXT' | translate }}</span>
      </ng-template>
      <ng-template ngbPaginationFirst>
        <span>{{ 'FIRST' | translate }}</span>
      </ng-template>
      <ng-template ngbPaginationLast>
        <span>{{ 'LAST' | translate }}</span>
      </ng-template>
    </ngb-pagination>
  </div>
</div>
<!-- Modal Content -->
<div class="relative">
  <ng-template #modalContent let-close="close">
    <div class="modal-body p-8">
      <div class="absolute flex items-center right-2 top-2 z-10">
        <button type="button" class="p-2.5" (click)="close()">
          <mat-icon svgIcon="closeIcon" class="!w-[12px] !h-[12px]"></mat-icon>
        </button>
      </div>
      <div class="w-full text-center text-lg font-semibold text-gray-900">View Services</div>
      <div class="w-full pt-3">
        <div class="bg-LightSecondary rounded-lg !p-4">
          <div class="text-lg font-medium">{{ item.Name }}</div>
          <div class="flex items-center justify-between pt-1">
            <div class="bg-Border text-gray-500 px-2 py-0.5">{{ item?.Category || '' }}</div>
            <div class="text-Secondary font-medium">${{ (item.Amount / 100).toFixed(2) }}/h</div>
          </div>
        </div>
        <div class="border-b !border-Border">
          <p class="text-base font-medium !pt-4">Activity Description</p>
          <p class="text-gray-400">
            {{ item?.Description }}
          </p>
          <p class="text-base font-medium" *ngIf="item?.SpecialInstruction">Special Instructions</p>
          <p class="text-gray-400" *ngIf="item?.SpecialInstruction">
            {{ item?.SpecialInstruction }}
          </p>
        </div>
        <div class="flex border-b !border-Border py-3">
          <div class="w-1/2 flex">
            <mat-icon svgIcon="calendarOutline" class="!w-5 !h-5 text-TextContainer"></mat-icon>
            <label class="ml-2 text-TextContainer">{{ 'DEFAULT_SERVICE_TIMES' | translate }}</label>
          </div>
          <div class="w-1/2 font-medium text-TextContainer">{{ item?.DefaultAmountTime }}</div>
        </div>
        <div class="flex items-center mt-3">
          <mat-icon svgIcon="location" class="!w-[18px] !h-[18px] text-Primary"></mat-icon>
          <span class="text-TextContainer pl-2">{{ item?.StartAddress }}</span>
        </div>
        <div class="flex items-center">
          <mat-icon svgIcon="dash" class="!w-[18px] !h-[18px]"></mat-icon>
        </div>
        <div class="flex items-center">
          <mat-icon svgIcon="location" class="!w-[18px] !h-[18px] text-Secondary"></mat-icon>
          <span class="text-TextContainer pl-2">{{ item?.EndAddress }}</span>
        </div>
      </div>
    </div>
  </ng-template>
</div>
