<div class="container-fluid d-flex flex-column align-items-sm-center h-100 p-0">
  <div class="row w-100 h-100 m-auto">
    <div name="left-side" id="left-side" class="col-lg-5 p-0">
      <div name="section-box" id="section-box" class="section-box login-color">
        <section name="section-content" id="section-content" class="section-content">
          <form name="login-form" id="login-form" class="form form-box sherpa-box" [formGroup]="createSherpaForm"
            (ngSubmit)="CreateSherpaProcess()">
            <div class="section-header">
              <img class="logo-sherpa" src="./assets/image/Temp.png" alt="Go-Sherpa" />
            </div>
            <div class="section-content-inner">
              <h1 class="top-head top-heading">Register to become a</h1>
              <h1 class="top-head top-heading">
                <strong style="font-weight: bold">{{ 'VERIFIED_SHERPA' | translate }}</strong>
              </h1>
            </div>

            <div class="edit-form-container">
              <div class="create-sherpa-info">
                <div class="row">
                  <div class="col-12 col-sm-6">
                    <div>
                      <mat-label class="sherpa-labels">{{ 'FIRST_NAME' | translate }}</mat-label>
                      <mat-form-field appearance="outline" class="sherpa-form-field">
                        <input matInput formControlName="FirstName" placeholder="{{ 'FIRST_NAME' | translate }}" autocomplete="off" />
                        <mat-error class="reduce-bottom-space" *ngIf="createSherpaForm.controls['FirstName'].invalid">
                          <mat-error class="reduce-bottom-space"
                            *ngIf="createSherpaForm.controls['FirstName'].errors?.['required']">
                            {{ 'FIRST_NAME_REQUIRED' | translate }}!
                          </mat-error>
                          <mat-error class="reduce-bottom-space"
                            *ngIf="createSherpaForm.controls['FirstName'].errors?.['minlength']">
                            {{ 'MIN_3_CHAR' | translate }}
                          </mat-error>
                          <mat-error class="reduce-bottom-space"
                            *ngIf="createSherpaForm.controls['FirstName'].errors?.['maxlength']">
                            Maximum 20 characters are allowed!
                          </mat-error>
                          <mat-error class="reduce-bottom-space"
                            *ngIf="createSherpaForm.controls['FirstName'].errors?.['pattern'] && ! createSherpaForm.controls['FirstName'].errors?.['minlength'] && ! createSherpaForm.controls['FirstName'].errors?.['maxlength']">
                            {{ 'FIRST_NAME_VALID' | translate }}
                          </mat-error>
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="col-12 col-sm-6">
                    <div>
                      <mat-label class="sherpa-labels">{{ 'LAST_NAME' | translate }}</mat-label>
                      <mat-form-field appearance="outline" class="sherpa-form-field">
                        <input matInput formControlName="LastName" placeholder="Last Name" autocomplete="off" />
                        <mat-error class="reduce-bottom-space" *ngIf="createSherpaForm.controls['LastName'].invalid">
                          <mat-error class="reduce-bottom-space"
                            *ngIf="createSherpaForm.controls['LastName'].errors?.['required']">
                            {{ 'LAST_NAME_REQUIRED' | translate }}
                          </mat-error>
                          <mat-error class="reduce-bottom-space"
                            *ngIf="createSherpaForm.controls['LastName'].errors?.['minlength']">
                            {{ 'MIN_3_CHAR' | translate }}
                          </mat-error>
                          <mat-error name="primary-details-col-1-error-4" id="primary-details-col-1-error-4"
                            class="reduce-bottom-space"
                            *ngIf="createSherpaForm.controls['LastName'].errors?.['maxlength']">
                            Maximun 20 characters are allowed!
                          </mat-error>
                          <mat-error class="reduce-bottom-space"
                            *ngIf="createSherpaForm.controls['LastName'].errors?.['pattern'] && ! createSherpaForm.controls['LastName'].errors?.['minlength'] && ! createSherpaForm.controls['LastName'].errors?.['maxlength']">
                            {{ 'LAST_NAME_VALID' | translate }}
                          </mat-error>
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="col-12 col-sm-6">
                    <div class="drop-down">
                      <mat-label class="sherpa-labels">{{ 'DATE_OF_BIRTH' | translate }}</mat-label>
                      <mat-form-field appearance="outline" class="sherpa-form-field">
                        <input matInput formControlName="DateOfBirth" [matDatepicker]="picker" placeholder="01/01/1990"
                          [value]="dateNowISO | date : 'yyyy-MM-dd'" [max]="maxDate"
                          (dateChange)="valueofDOBChanged($event)" readonly />
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                        <mat-error class="reduce-bottom-space" *ngIf="
                            createSherpaForm.controls['DateOfBirth'].invalid
                          ">
                          <mat-error class="reduce-bottom-space"
                            *ngIf="createSherpaForm.controls['DateOfBirth'].errors?.['required']">
                            DOB is required!
                          </mat-error>
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="col-12 col-sm-12">
                    <div>
                      <mat-label class="sherpa-labels">{{ 'MOBILE_NUMBER' | translate }}</mat-label>
                      <mat-form-field appearance="outline" class="sherpa-form-field">
                        <input matInput phoneMask type="text" inputmode="numeric"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57" onselectstart="return false"
                          onpaste="return false" onCopy="return false" onCut="return false" onDrag="return false"
                          onDrop="return false" id="phone" formControlName="PhoneNumber" placeholder="111-111-1111"
                          minlength="12" maxlength="12" />
                        <mat-error class="reduce-bottom-space" *ngIf="
                            createSherpaForm.controls['PhoneNumber'].invalid
                          ">
                          <mat-error class="reduce-bottom-space"
                            *ngIf="createSherpaForm.controls['PhoneNumber'].errors?.['required']">
                            {{ 'MOBILE_NUMBER_REQUIRED' | translate }}
                          </mat-error>
                          <mat-error class="reduce-bottom-space"
                            *ngIf="createSherpaForm.controls['PhoneNumber'].errors?.['minlength']">
                            {{ 'MIN_10_CHAR' | translate }}
                          </mat-error>
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="col-12 col-sm-12">
                    <div>
                      <mat-label class="sherpa-labels">{{ 'EMAIL_ADDRESS' | translate }}</mat-label>
                      <mat-form-field appearance="outline" class="sherpa-form-field disable-field">
                        <input matInput formControlName="vEmailAddress" placeholder="user@gmail.com" autocomplete="off"
                          class="not-allow" readonly />
                        <mat-error class="reduce-bottom-space" *ngIf="
                            createSherpaForm.controls['vEmailAddress'].invalid
                          ">
                          <mat-error class="reduce-bottom-space"
                            *ngIf="createSherpaForm.controls['vEmailAddress'].errors?.['required']">
                            {{ 'EMAIL_REQUIRED' | translate  }}
                          </mat-error>
                          <mat-error class="reduce-bottom-space"
                            *ngIf="createSherpaForm.controls['vEmailAddress'].errors?.['email'] || createSherpaForm.controls['vEmailAddress'].errors?.['pattern']">
                            {{ 'YOUR_EMAIL_INVALID' | translate }}
                          </mat-error>
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="col-12 col-sm-12">
                    <div>
                      <mat-label class="sherpa-labels">Street Address</mat-label>
                      <mat-form-field appearance="outline" class="sherpa-form-field">
                        <input matInput formControlName="Address" placeholder="Street Address" autocomplete="off" />
                        <mat-error class="reduce-bottom-space" *ngIf="createSherpaForm.controls['Address'].invalid">
                          <mat-error class="reduce-bottom-space"
                            *ngIf="createSherpaForm.controls['Address'].errors?.['required']">
                            Street Address is required!
                          </mat-error>
                          <mat-error class="reduce-bottom-space"
                            *ngIf="createSherpaForm.controls['Address'].errors?.['minlength']">
                            Minimum 10 characters are required!
                          </mat-error>
                          <mat-error class="reduce-bottom-space"
                            *ngIf="createSherpaForm.controls['Address'].errors?.['maxlength']">
                            {{ 'NAME_MAX_50' | translate }}
                          </mat-error>
                          <mat-error class="reduce-bottom-space"
                            *ngIf="createSherpaForm.controls['Address'].errors?.['pattern'] && ! createSherpaForm.controls['Address'].errors?.['minlength'] && ! createSherpaForm.controls['Address'].errors?.['maxlength']">
                            Please enter valid address!
                          </mat-error>
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="col-12 col-sm-12">
                    <div>
                      <mat-label class="sherpa-labels">Unit (optional)</mat-label>
                      <mat-form-field appearance="outline" class="sherpa-form-field">
                        <input matInput formControlName="Unit" placeholder="Unit (optional)" autocomplete="off" />
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="col-12 col-sm-12">
                    <div class="drop-down">
                      <mat-label class="sherpa-labels">Activity Preference</mat-label>
                      <mat-form-field appearance="outline" class="sherpa-form-field">
                        <mat-select formControlName="ActivityPreference" placeholder="Activity Preference">
                          <mat-option *ngFor="let preference of CATEGORY" [value]="preference">
                            {{ preference }}
                          </mat-option>
                        </mat-select>
                        <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
                        <mat-error class="reduce-bottom-space" *ngIf="
                            createSherpaForm.controls['ActivityPreference']
                              .invalid
                          ">
                          <mat-error class="reduce-bottom-space"
                            *ngIf="createSherpaForm.controls['ActivityPreference'].errors?.['required']">
                            {{ 'ACTIVITY_PREFERENCE_REQUIRED' | translate }}
                          </mat-error>
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="col-12 col-sm-12">
                    <div class="drop-down">
                      <mat-label class="sherpa-labels">{{ 'ACTIVITY_EXPERIENCE' | translate }}</mat-label>
                      <mat-form-field appearance="outline" class="sherpa-form-field">
                        <mat-select formControlName="ActivityExpertise" placeholder="{{ 'ACTIVITY_EXPERIENCE' | translate }}">
                          <mat-option *ngFor="let exp of Experience" [value]="exp">
                            {{ exp }}
                          </mat-option>
                        </mat-select>
                        <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
                        <mat-error class="reduce-bottom-space" *ngIf="
                            createSherpaForm.controls['ActivityExpertise']
                              .invalid
                          ">
                          <mat-error class="reduce-bottom-space"
                            *ngIf="createSherpaForm.controls['ActivityExpertise'].errors?.['required']">
                            {{ 'ACTIVITY_PREFERENCE_REQUIRED' | translate }}
                          </mat-error>
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="col-12 col-sm-12 mb-3">
                    <mat-checkbox class="checkbox-text" #confirmed>I understand and agree to <a href="https://vault.pactsafe.io/s/55e402f4-db1c-4254-9913-3d2c28b9990b/legal.html?g=40941#template-oyjnztv-m" title="Privacy Policy" target="_blank">Privacy Policy</a> and <a href="https://vault.pactsafe.io/s/55e402f4-db1c-4254-9913-3d2c28b9990b/legal.html?g=40941#template-wojx6zzez" title="Term of Service" target="_blank">Terms
                      <br> of Service</a></mat-checkbox>
                  </div>
                </div>
              </div>
            </div>

            <div name="form-footer" id="form-footer" class="form-footer">
              <button type="submit" name="form-button" id="form-button" class="btn" [disabled]="!confirmed.checked">
                Submit
              </button>
            </div>
          </form>
        </section>
      </div>
    </div>
    <div name="right-side" id="right-side" class="col-lg-7 d-none d-lg-block p-0">
      <div name="section-side-solid-box" id="section-side-solid-box" class="section-side-sherpa-box h-100"></div>
    </div>
  </div>
</div>