import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Message } from '../utils/envencryption';
@Injectable({
  providedIn: 'root'
})
export class CreateVerifiedSherpaAccountService {

  constructor(private readonly http:HttpClient) {}

  baseUrl = environment.baseUrl

  public CreateVerifiedSherpa(data:any , id:any ) {
    return this.http.put(`${this.baseUrl}verifiedUser/v1/create?token=${id}`, data);
  }

  public getBecomeVerifiedUserDetails(_id:any, data: any) {
    // return this.http.get(this.baseUrl+"verifiedUser/v1/getBecomeVerifiedUserDetails/"+_id, data);
    return this.http.get(`${this.baseUrl}verifiedUser/v1/getBecomeVerifiedUserDetails/${_id}`, data);
  }

  public CheckVerifiedToken(id:any){
    return this.http.get(this.baseUrl+`common/v1/checkSherpaQueryToken?token=${id}`);
  }
}
