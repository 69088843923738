import { Component, Input, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as _ from 'lodash';
import { ACCOUNT_TYPE } from 'src/app/utils/constant';

@Component({
  selector: 'app-profile-component',
  templateUrl: './profile.component.html',
  providers: [DatePipe],
})
export class ProfileComponent implements OnInit {
  @Input() user: any;
  accountType = ACCOUNT_TYPE;
  constructor() {}

  ngOnInit(): void {}
  setShowCofirm() {}

  getFullName() {
    return _.trim(`${this.user?.FirstName || ''} ${this.user?.LastName || ''}`);
  }
}
