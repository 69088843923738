import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { createQuery } from 'src/app/utils/helper';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class ConversationsService {
  constructor(private http: HttpClient) {}

  baseUrl = environment.baseUrl;
  items: any = [];

  public getConversations(params: any, callback: any) {
    const item = this.items.find((it: any) => _.isEqual(it.params, params));
    if (item) {
      callback(item.data);
    } else {
      this.http.get(this.baseUrl + 'messages/v1/getConversations?' + createQuery(params)).subscribe(data => {
        this.items = _.concat(this.items, { params, data });
        callback(data);
      });
    }
  }
}
