<div class="container-fluid d-flex flex-column align-items-sm-center h-100 p-0">
    <div class="row w-100 h-100 m-auto">
        <div  name="left-side" id="left-side" class="col-lg-5 p-0">
            <div class="section-box change-color">
                <section class="section-content">
                    <form class="form form-box inner-form-box" [formGroup]="changePasswordForm" (ngSubmit)="HandleChangePasswordSubmit()">
                        <div class="section-left-header section-left-logo">
                            <img class="logo-sherpa" src="./assets/image/Temp.png" alt="Digital-Empathy" />
                        </div>
                        <div name="form-header" id="form-header" class="text-left form-header">
                            <h1 class="form-title mb-5">{{ 'CHANGE_PASSWORD' | translate }}</h1>
                        </div>
                        <div class="form-content">
                            <mat-form-field hideRequiredMarker appearance="outline" floatLabel="never" class="">
                                <input matInput required [type]="hideOldPassword ? 'password' : 'text'"  placeholder="Old Password" formControlName="OldPassword" (keydown.space)="$event.preventDefault()" autocomplete="off"/>
                                <mat-icon matSuffix (click)="hideOldPassword = !hideOldPassword" class="d-flex justify-content-center align-items-center h-100 visibility-eye">{{ hideOldPassword ? "visibility_off" : "visibility" }}
                                </mat-icon>
                                <mat-error class="reduce-bottom-space"
                                    *ngIf="changePasswordForm.controls['OldPassword'].invalid">
                                    <mat-error class="reduce-bottom-space"
                                        *ngIf="changePasswordForm.controls['OldPassword'].errors?.['required']">{{ 'OLD_PASS_REQUIRED' | translate }}</mat-error>
                                    <mat-error class="reduce-bottom-space"
                                        *ngIf="changePasswordForm.controls['OldPassword'].errors?.['minlength']">
                                        {{ 'PASSWORD_MUST' | translate }}
                                    </mat-error>
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field hideRequiredMarker appearance="outline" floatLabel="never">
                                <input matInput required [type]="hidePassword ? 'password' : 'text'"  placeholder="New Password" formControlName="NewPassword" (keydown.space)="$event.preventDefault()" autocomplete="off"/>
                                <mat-icon matSuffix (click)="hidePassword = !hidePassword" class="d-flex justify-content-center align-items-center h-100 visibility-eye">{{ hidePassword ? "visibility_off" : "visibility" }}
                                </mat-icon>
                                <mat-error class="reduce-bottom-space"
                                    *ngIf="changePasswordForm.controls['NewPassword'].invalid">
                                    <mat-error class="reduce-bottom-space"
                                        *ngIf="changePasswordForm.controls['NewPassword'].errors?.['required']">{{ 'NEW_PASS_REQUIRED' | translate }}</mat-error>
                                    <mat-error class="reduce-bottom-space"
                                        *ngIf="changePasswordForm.controls['NewPassword'].errors?.['minlength']">
                                        {{ 'PASSWORD_MUST' | translate }}
                                    </mat-error>
                                    <mat-error class="reduce-bottom-space" *ngIf="changePasswordForm.controls['NewPassword'].errors?.['minlength'] || changePasswordForm.controls['NewPassword'].errors?.['pattern']">
                                        {{ 'PASSWORD_MUST_NOTE' | translate }}
                                    </mat-error>
                                    <mat-error *ngIf="changePasswordForm.controls['NewPassword'].errors?.['matching']">
                                        {{ 'PASSWORD_CONFIRM' | translate }}
                                    </mat-error>
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field hideRequiredMarker appearance="outline" floatLabel="never">
                                <input matInput required [type]="hideNewPassword ? 'password' : 'text'" placeholder="Confirm New Password" formControlName="ConfirmPassword" (keydown.space)="$event.preventDefault()" autocomplete="off"/>
                                <mat-icon matSuffix (click)="hideNewPassword = !hideNewPassword" class="d-flex justify-content-center align-items-center h-100 visibility-eye">{{ hideNewPassword ? "visibility_off" : "visibility" }}
                                </mat-icon>
                                <mat-error class="reduce-bottom-space"
                                    *ngIf="changePasswordForm.controls['ConfirmPassword'].invalid">
                                    <mat-error class="reduce-bottom-space"
                                        *ngIf="changePasswordForm.controls['ConfirmPassword'].errors?.['required']">{{ 'CONFIRM_NEW_PASS_REQUIRED' | translate }}</mat-error>
                                    <mat-error class="reduce-bottom-space"
                                        *ngIf="changePasswordForm.controls['ConfirmPassword'].errors?.['minlength']">
                                        {{ 'PASSWORD_MUST' | translate }}
                                    </mat-error>
                                    <mat-error class="reduce-bottom-space" *ngIf="changePasswordForm.controls['ConfirmPassword'].errors?.['minlength'] || changePasswordForm.controls['ConfirmPassword'].errors?.['pattern']">
                                        {{ 'PASSWORD_MUST_NOTE' | translate }}
                                    </mat-error>
                                    <mat-error *ngIf="changePasswordForm.controls['ConfirmPassword'].errors?.['matching']">
                                        {{ 'PASSWORD_CONFIRM' | translate }}
                                    </mat-error>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="form-footer">
                            <button type="submit" name="form-button" id="form-button" class="btn">
                            {{ 'CHANGE_PASSWORD' | translate }}
                            </button>
                        </div>
                    </form>
                </section>
            </div>
        </div>
        <div name="right-side" id="right-side" class="col-lg-7 d-none d-lg-block p-0 h-100">
            <div name="section-side-solid-box" id="section-side-solid-box" class="section-side-changepswd-box h-100">
            </div>
        </div>
    </div>
</div>
