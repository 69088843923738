import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
const mapboxUrl = 'https://api.mapbox.com/geocoding/v5/mapbox.places';
const accessToken = environment.mapBoxAccessToken;
import { map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MapboxService {
  trackingSearch: any = {};

  constructor(private http: HttpClient) { }

  public geocoding(searchText: any) {
    const tracking = this.trackingSearch[searchText];
    if (tracking) {
      return tracking;
    }
    const h = this.http.get(`${mapboxUrl}/${searchText}.json?country=US&limit=5&access_token=${accessToken}`, { headers: { notLoading: 'true' } });
    return h.pipe(
      map(result => {
        const value = new BehaviorSubject({});
        value.next(result);
        this.trackingSearch[searchText] = value;
        return result;
      }),
    );
  }
}
