import { Component, Input, OnInit } from '@angular/core';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions } from '@angular/material/tooltip';
import { MapboxService } from 'src/app/core/services/mapbox.service';
import * as _ from 'lodash';
import { FormGroup } from '@angular/forms';

export const myCustomTooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 100,
  hideDelay: 1000,
  touchendHideDelay: 1000,
};

@Component({
  selector: 'app-input-suggestions',
  templateUrl: './input-suggestions.component.html',
  styleUrls: ['./input-suggestions.component.scss'],
  providers: [{ provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: myCustomTooltipDefaults }],
})
export class InputSuggestionsComponent implements OnInit {
  @Input() name: any;
  @Input() placeholder: any;
  @Input() error: any = null;
  @Input() handelChange: any = () => {};
  @Input() value: any;
  searchText = '';
  oldSearchText = '';
  isApiLoading = false;
  listAddress: any[] = [];
  showSuggestions = false;
  inputForm!: FormGroup;

  constructor(private mapboxService: MapboxService) {}

  ngOnInit() {
    this.searchText = this.value || '';
  }

  onBlurMethod(event: any) {
    setTimeout(() => {
      this.showSuggestions = false;
    }, 200);
  }

  onFocusMethod(event: any) {
    if (this.searchText !== '') {
      this.oldSearchText = '';
      this.searcAddress(this.searchText)
    }
  }

  handleClick = (address: any) => {
    this.searchText = address.fullAddress;
    this.oldSearchText = address.fullAddress;
    this.showSuggestions = false;
    this.handelChange(address.fullAddress);
  };

  searcAddress = (searchText: any) => {
    this.handelChange(searchText);
    if (searchText.length < 3 || this.isApiLoading || this.oldSearchText === searchText) {
      this.oldSearchText = searchText;
      this.showSuggestions = false;
      return;
    }
    this.isApiLoading = true;
    this.mapboxService.geocoding(searchText).subscribe((response: any) => {
      this.listAddress = response?.features?.map((ft: any) => ({
        firstAddress: _.first(_.split(ft.place_name, ', ')),
        lastAddress: _.join(_.drop(_.split(ft.place_name, ', ')), ', '),
        fullAddress: ft.place_name,
      }));
      this.showSuggestions = true;
      this.isApiLoading = false;
      this.oldSearchText = searchText;
    });
  };
}
