<div class="message-component flex flex-1 flex-col p-4 bg-white">
  <div class="scrollbar overflow-auto scrollbar-content scroll-auto flex flex-col-reverse h-full mb-2 px-2" (scroll)="onScroll($event)">
    <div class="scrollbar-content-scroll">
      <div class="flex flex-col-reverse py-1">
        <div *ngFor="let message of messageCovers">
          <div class="{{ checkUser(message) ? 'justify-end' : 'justify-start' }} flex mb-2.5" *ngIf="message._id">
            <div class="h-12 w-14 flex justify-start" *ngIf="!checkUser(message)">
              <div *ngIf="message.showImage">
                <div *ngIf="!(message.User || message.VerifiedUser)?.Images?.[0]">
                  <div class="bg-[#EDEDED] w-full h-full rounded-full flex items-center justify-center">
                    <mat-icon svgIcon="user" class="!w-12 !h-12"></mat-icon>
                  </div>
                </div>
                <div *ngIf="(message.User || message.VerifiedUser)?.Images?.[0] as image">
                  <img src="{{ image }}" class="object-cover rounded-full h-12 w-12" />
                </div>
              </div>
            </div>
            <div *ngIf="message.Files.length === 1" (click)="setShowImage(message.Files[0])">
              <div class="overflow-hidden rounded-[10px] w-[280px] h-[280px] cursor-pointer">
                <img src="{{ message.Files[0] }}" class="object-cover w-full h-full" />
              </div>
            </div>
            <div *ngIf="message.Files.length === 2">
              <div class="{{ checkUser(message) ? 'bg-LightPrimary rounded-tl-[20px]' : 'bg-LightGray rounded-tr-[20px]' }} rounded-b-[20px]">
                <div class="p-2.5 grid grid-cols-2 gap-2.5 w-[280px]">
                  <div *ngFor="let file of message.Files" (click)="setShowImage(file)">
                    <div class="overflow-hidden rounded-[10px] w-[125px] h-[125px] cursor-pointer">
                      <img src="{{ file }}" class="object-cover w-full h-full" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="message.Files.length > 2">
              <div class="{{ checkUser(message) ? 'bg-LightPrimary rounded-tl-[20px]' : 'bg-LightGray rounded-tr-[20px]' }} rounded-b-[20px]">
                <div class="flex-1 p-2.5 grid grid-cols-3 gap-2.5 w-[280px]">
                  <div *ngFor="let file of message.Files" (click)="setShowImage(file)">
                    <div class="overflow-hidden rounded-[10px] w-[80px] h-[80px] cursor-pointer">
                      <img src="{{ file }}" class="object-cover w-full h-full" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="message.Files.length === 0" class="max-w-[400px]">
              <div class="{{ checkUser(message) ? 'bg-LightPrimary rounded-tl-3xl' : 'bg-LightGray rounded-tr-3xl' }} !p-4 rounded-b-3xl">
                <span class="text-sm text-TextContainer break-words">{{ message.Text }}</span>
              </div>
            </div>
            <div class="h-12 w-14 flex justify-end" *ngIf="checkUser(message)">
              <div *ngIf="message.showImage">
                <div *ngIf="!(message.User || message.VerifiedUser)?.Images?.[0]">
                  <div class="bg-[#EDEDED] w-full h-full rounded-full flex items-center justify-center">
                    <mat-icon svgIcon="user" class="!w-12 !h-12"></mat-icon>
                  </div>
                </div>
                <div *ngIf="(message.User || message.VerifiedUser)?.Images?.[0] as image">
                  <img src="{{ image }}" class="object-cover rounded-full h-12 w-12" />
                </div>
              </div>
            </div>
          </div>
          <div class="flex justify-center mb-2.5" *ngIf="!message._id">
            <div class="p-2.5 font-medium text-xs text-gray-500">{{ formatTime(message.createdAt) }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="w-full flex" *ngIf="!isDisable">
    <input class="flex-1 border border-[#E0E0E0] !border-r-0 rounded-l-full px-4" placeholder="Type a message..." [(ngModel)]="searchText" />
    <input type="file" style="display: none" (change)="onFileChange($event)" #fileInput multiple />
    <button class="flex !border-y border-0 !border-solid !border-[#E0E0E0] items-center justify-center w-[50px] h-[50px]" (click)="fileInput.click()">
      <mat-icon svgIcon="attach" class="!w-[20px] !h-[20px]"></mat-icon>
    </button>
    <button (click)="handleSendMessage()">
      <div class="bg-Secondary border !border-Secondary flex items-center justify-center w-[50px] h-[50px] rounded-r-full">
        <mat-icon svgIcon="subtract" class="!w-[20px] !h-[20px]"></mat-icon>
      </div>
    </button>
  </div>
  <div class="relative">
    <ng-template #modalImage let-close="close">
      <div class="flex items-center justify-center relative">
        <div class="absolute flex items-center right-2 top-2 z-10">
          <button type="button" class="p-2.5" (click)="close()">
            <mat-icon svgIcon="closeIcon" class="!w-[12px] !h-[12px]"></mat-icon>
          </button>
        </div>
        <div class="w-full min-h-[70vh] max-h-[90vh] bg-gray-800 flex items-center justify-center">
          <img class="max-w-full max-h-[90vh]" src="{{ imageUri }}" />
        </div>
      </div>
    </ng-template>
  </div>
</div>
