<div class="container-fluid d-flex flex-column align-items-sm-center h-100 p-0">
    <div class="row w-100 h-100 m-auto">
        <div  name="left-side" id="left-side" class="col-lg-5 p-0">
            <div class="section-box reset-color">
                <section class="section-content validation-container">
                    <form class="form form-box inner-form-box" [formGroup]="resetForm" (ngSubmit)="HandleResetPasswordSubmit()">
                        <div name="form-header" id="form-header" class="text-left form-header">
                            <h1 class="form-title">{{ 'SET_NEW_PASS' | translate }}</h1>
                            <p class="form-subtitle subtitle-resetpswd-color">{{ 'SET_NEW_PASS_NOTE' | translate }}</p>
                        </div>
                        <div class="form-content">
                            <mat-form-field hideRequiredMarker appearance="outline" name="mat-form-field-password" id="mat-form-field-password">
                                <input matInput required [type]="hidePassword ? 'password' : 'text'"  placeholder="New Password" formControlName="NewPassword" (keydown.space)="$event.preventDefault()" autocomplete="off" />
                                <mat-icon matSuffix (click)="hidePassword = !hidePassword" class="d-flex justify-content-center align-items-center h-100 visibility-eye">{{ hidePassword ? "visibility_off" : "visibility" }}
                                </mat-icon>
                                <mat-error class="reduce-bottom-space"
                                    *ngIf="resetForm.controls['NewPassword']?.['invalid'] || submitted || resetForm.controls['NewPassword']?.errors">
                                    <mat-error class="reduce-bottom-space" *ngIf="resetForm.controls['NewPassword'].errors?.['required']">
                                        {{ 'PASS_REQUIRED' | translate }}.
                                    </mat-error>
                                    <mat-error class="reduce-bottom-space"
                                        *ngIf="resetForm.controls['NewPassword'].errors?.['minlength']">
                                        {{ 'PASS_LENGTH' | translate }}
                                    </mat-error>
                                    <mat-error class="reduce-bottom-space" *ngIf="resetForm.controls['NewPassword'].errors?.['pattern']">
                                        {{ 'PASSWORD_MUST_NOTE' | translate }}
                                    </mat-error>
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field hideRequiredMarker appearance="outline" name="mat-form-field-password" id="mat-form-field-password">
                                <input matInput required [type]="hideResetPassword ? 'password' : 'text'" placeholder="Confirm New Password" formControlName="ConfirmPassword" (keydown.space)="$event.preventDefault()" autocomplete="off" />
                                <mat-icon matSuffix (click)="hideResetPassword = !hideResetPassword" class="d-flex justify-content-center align-items-center h-100 visibility-eye">{{ hideResetPassword ? "visibility_off" : "visibility" }}
                                </mat-icon>
                                <mat-error class="reduce-bottom-space" *ngIf="resetForm.controls['ConfirmPassword'].invalid">
                                    <mat-error class="reduce-bottom-space" *ngIf="resetForm.controls['ConfirmPassword'].errors?.['required']">
                                        {{ 'NEW_PASS_REQUIRED' | translate }}.
                                    </mat-error>
                                    <mat-error class="reduce-bottom-space"
                                        *ngIf="resetForm.controls['ConfirmPassword'].errors?.['minlength']">
                                        Password Length must 12 characters
                                    </mat-error>
                                    <mat-error class="reduce-bottom-space" *ngIf="resetForm.controls['ConfirmPassword'].errors?.['minlength'] || resetForm.controls['ConfirmPassword'].errors?.['pattern']">
                                        {{ 'PASSWORD_MUST_NOTE' | translate }}
                                    </mat-error>
                                    <mat-error class="reduce-bottom-space" *ngIf="resetForm.controls['ConfirmPassword'].errors?.['matching']">
                                        {{ 'PASSWORD_CONFIRM' | translate }}.
                                    </mat-error>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="form-footer">
                            <button type="submit" name="form-button" id="form-button" class="btn">
                            {{ 'SAVE_NEW_PASS' | translate }}
                            </button>
                        </div>
                    </form>
                </section>
            </div>
        </div>
        <div name="right-side" id="right-side" class="col-lg-7 d-none d-lg-block p-0">
            <div name="section-side-solid-box" id="section-side-solid-box" class="section-side-reset-box h-100">
            </div>
        </div>
    </div>
</div>
