<mat-sidenav-container class="sidenav-container">
  <mat-sidenav
    #drawer
    class="sidenav"
    fixedInViewport
    id="default-sidebar"
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="(isHandset$ | async) === false"
  >
    <div class="top-logo">
      <a href="" class="simple-text">
        <div class="logo-img">
          <img src="./assets/image/Temp.png" class="logo" alt="logo" />
        </div>
      </a>
    </div>
    <mat-nav-list class="menu-items">
      <app-sidebar (click)="closeSideNav()"></app-sidebar>
    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content>
    <mat-toolbar class="layouts-container">
      <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()" *ngIf="isHandset$ | async" class="ms-3">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <div class="admin-button-wrapper">
        <button mat-button [matMenuTriggerFor]="menu" class="logout" style="font-size: 16px; font-weight: 400">
          <div class="!flex items-center">
            <img *ngIf="profileImage" src="{{ profileImage }}" alt="user" />
            <img *ngIf="!profileImage" src="./assets/image/user-image.png" alt="user" />
            <span class="admin-title">{{ profileFirstName }} {{ profileLastName }}</span>
            <mat-icon>arrow_drop_down</mat-icon>
          </div>
        </button>
        <mat-menu #menu="matMenu" class="profile-menu mt-2">
          <button mat-menu-item (click)="viewAdminProfile()" class="!flex items-center">
            <mat-icon class="material-icons-outlined">account_circle</mat-icon><span>{{ 'MY_PROFILE' | translate }}</span>
          </button>
          <button mat-menu-item (click)="changePassword()" class="!flex items-center">
            <mat-icon class="material-icons-outlined">lock</mat-icon><span>{{ 'CHANGE_PASSWORD' | translate }}</span>
          </button>
          <button mat-menu-item (click)="openVerticallyCentered(delete_form)" class="!flex items-center">
            <mat-icon>logout</mat-icon><span>{{ 'LOG_OUT' | translate }}</span>
          </button>
        </mat-menu>
      </div>
    </mat-toolbar>

    <div class="admin-content-wrapper">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

<!-- Logout template -->

<ng-template #delete_form let-modal>
  <div class="delete-profile-modal-body">
    <h4>{{ 'CONFIRMATION' | translate }}</h4>
    <p class="delete-text">{{ 'CONFIRMATION_NOTE' | translate }}</p>
  </div>
  <div class="delete-form-modal-footer">
    <button type="button" class="cancel-btn uppercase" (click)="modal.close('Close click')">{{ 'CANCEL' | translate }}</button>
    <button type="button" class="done-btn" (click)="logout(); modal.close('Close click')">{{ 'LOGOUT' | translate }}</button>
  </div>
</ng-template>
