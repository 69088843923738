import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private readonly router: Router,
    private readonly authenticateService: AuthenticationService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      const allowedRoles = route.data['allowedRoles'];
      
    //   const isAuthorized = this.authenticateService.isAuthorized(allowedRoles);

    //   if (!isAuthorized) {
    //     // if not authorized, show access denied message
    //     localStorage.removeItem('auth_token');
    //     localStorage.removeItem('role');
    //     this.router.navigate(['/login']);
    //   }

    // return isAuthorized;
    return true;
  }
  
}
