import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { LoginService } from './login.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { NotificationService } from 'src/app/core/services/notification.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit {
  loginForm!: FormGroup;
  hidePassword = true;
  public submitted = false;
  loginToken: any = localStorage.getItem('auth_token');
  decodeToken: any;

  constructor(
    private readonly router: Router,
    private readonly loginService: LoginService,
    private readonly jwtHelperService: JwtHelperService,
    private readonly mainNotify: NotificationService,
  ) {}

  ngOnInit() {
    if (this.loginToken) {
      this.decodeToken = this.jwtHelperService.decodeToken(this.loginToken);      
      if (this.decodeToken.AccountType === 1) {
        this.router.navigate(['/athlete-page']);
      } else if (this.decodeToken.AccountType === 4 || this.decodeToken.AccountType === 5) {
        this.router.navigate(['/admin/user-management']);
      } else if (this.decodeToken.AccountType === 2) {
        this.router.navigate(['/verified-user/availability']);
      }
    }
    this.loginForm = new FormGroup({
      vEmailId: new FormControl('', [
        Validators.required,
        Validators.email,
        Validators.minLength(5),
        Validators.maxLength(70),
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
      ]),
      txPassword: new FormControl('', [Validators.required, Validators.minLength(8), Validators.pattern('^(?! ).*[^ ]$')]),
    });
    this.HandleLogin();
  }

  HandleLogin() {
    if (this.loginForm.invalid) {
      return;
    }
    this.submitted = true;
    const loginObj = {
      Email: this.loginForm.value.vEmailId,
      Password: this.loginForm.value.txPassword,
      DeviceType: 2,
    };
    this.loginService.login(loginObj).subscribe((res: any) => {
      // You have successfully logged in!
      if (res.code === 200) {        
        localStorage.setItem('auth_token', res.data.AccessToken);
        localStorage.setItem('role', res.data.AccountType);

        if (res.data.AccountType === 1) {
          this.router.navigate(['/athlete-page']);
        }else if (res.data.AccountType === 4 || res.data.AccountType === 5) {
          this.loginService.chageVerifiedSherpaId(res.data._id);
          this.mainNotify.showSuccess(res.message, '');
          this.router.navigate(['/admin/user-management']);
        } else if (res.data.AccountType === 2) {
          this.loginService.chageVerifiedSherpaId(res.data._id);
          this.mainNotify.showSuccess(res.message, '');
          this.router.navigate(['/verified-user/availability']);
        }
      }

      // Invalid credentials, please verify and retry.
      else if (res.code === 409) {
        this.mainNotify.showError(res.message, '');
      }

      // Unable to login! Please contact administrator.
      else if (res.code === 403) {
        this.mainNotify.showError(res.message, '');
      }

      // Sorry! You're not a admin. Please make admin first.
      else if (res.code === 406) {
        this.mainNotify.showError(res.message, '');
      }

      // Failed to Login. Please try again.
      else if (res.code === 400) {
        this.mainNotify.showError(res.message, '');
      }

      // Sorry! You're not a verified user. Please make verified user first.
      else if (res.code === 422) {
        this.mainNotify.showError(res.message, '');
      } else if (res.code === 429) {
        this.mainNotify.showError(res.message, '');
      } else {
        this.mainNotify.showError('Something Went Wrong...', '');
      }
    });
  }
}
