import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AthletePageService } from './athlete-page.service';
import jwt_decode from 'jwt-decode';

@Component({
  selector: 'app-athlete-page',
  templateUrl: './athlete-page.component.html',
})
export class AthletePageComponent implements OnInit {
  token: any = localStorage.getItem('auth_token');
  _id: any;
  decode: any;
  user: any;
  dateDoB: any;
  constructor(private router: Router, private athletePageService: AthletePageService) {
    if (this.token && this.token != null) {
      this.decode = jwt_decode(this.token);
      this._id = this.decode._id;
    }
  }

  ngOnInit() {}

  logout() {
    localStorage.removeItem('auth_token');
    localStorage.removeItem('role');
    this.router.navigate(['login']);
  }
  becomeToSherpa() {    
    this.athletePageService.verifiedUser({ Email: this.decode.Email }).subscribe((data: any)=> {
      this.router.navigate(['/register-sherpa'], { queryParams: { token: data.data.token } });
    });
  }
}
