<div class="signup container-fluid d-flex flex-column align-items-sm-center h-100 p-0 bg-white text-base pt-12">
  <div class="w-100 h-100 flex">
    <div name="left-side" id="left-side" class="w-full md:w-[500px] xl:w-[700px] p-4 overflow-auto">
      <div *ngIf="!signupSuccess" class="md:w-[440px] xl:w-[500px] m-auto">
        <div class="mb-2">
          <img class="w-16" src="./assets/image/Temp.png" alt="Go-Sherpa-icon" />
        </div>
        <p class="text-2xl md:text-3xl mb-4">{{ 'BECOME_SHERPA_TITLE' | translate }}</p>
        <input type="hidden" id="userEmailInput" [value]="user?.Email" />
        <form [formGroup]="createSherpaForm" (ngSubmit)="handleCreateUserVerified()" class="form">
          <div class="w-full grid grid-cols-1 md:grid-cols-2 gap-x-4">
            <div>
              <mat-form-field hideRequiredMarker appearance="outline" class="w-full">
                <mat-label>{{ 'FIRST_NAME' | translate }}*</mat-label>
                <input matInput required type="text" formControlName="FirstName" />
              </mat-form-field>
            </div>
            <div>
              <mat-form-field hideRequiredMarker appearance="outline" class="w-full">
                <mat-label>{{ 'LAST_NAME' | translate }}*</mat-label>
                <input matInput required type="text" formControlName="LastName" />
              </mat-form-field>
            </div>
            <div>
              <mat-form-field hideRequiredMarker appearance="outline" class="w-full">
                <mat-label>{{ 'MOBILE_NUMBER' | translate }}</mat-label>
                <input
                  matInput
                  mobMask
                  type="text"
                  inputmode="numeric"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                  onselectstart="return false"
                  onpaste="return false"
                  onCopy="return false"
                  onCut="return false"
                  onDrag="return false"
                  onDrop="return false"
                  id="phone"
                  formControlName="PhoneNumber"
                  minlength="12"
                  maxlength="12"
                />
              </mat-form-field>
            </div>
            <div>
              <mat-form-field hideRequiredMarker appearance="outline" class="w-full">
                <mat-label>{{ 'DATE_OF_BIRTH' | translate }}</mat-label>
                <input readonly matInput [matDatepicker]="pickerEnd" class="p-0" formControlName="DateOfBirth" />
                <mat-datepicker-toggle [for]="pickerEnd"></mat-datepicker-toggle>
                <mat-datepicker #pickerEnd></mat-datepicker>
              </mat-form-field>
            </div>
            
            <div class="md:col-span-2 pb-2">
              <app-input-suggestions
                name="Address"
                [handelChange]="handelChangeAddress"
                placeholder="Address"
                [error]="errorAddress"
                [value]="createSherpaForm.value.Address"
              ></app-input-suggestions>
            </div>
            <div class="md:col-span-2">
              <mat-form-field appearance="outline" class="service-field w-full">
                <mat-label>{{ 'ABOUT_ME' | translate }}</mat-label>
                <textarea matInput formControlName="AboutMe" rows="4"></textarea>
                <mat-error class="reduce-bottom-space" *ngIf="createSherpaForm.controls['AboutMe'].invalid">
                  <mat-error class="reduce-bottom-space" *ngIf="createSherpaForm.controls['AboutMe'].errors?.['minlength']">
                    {{ 'MIN_50_REQUIED' | translate }}
                  </mat-error>
                </mat-error>
              </mat-form-field>
            </div>
            <div>
              <mat-form-field appearance="outline" class="service-field w-full">
                <mat-label>{{ 'TRAINING_GOALS' | translate }}</mat-label>
                <textarea matInput formControlName="TrainingGoals" rows="4"></textarea>
                <mat-error class="reduce-bottom-space" *ngIf="createSherpaForm.controls['TrainingGoals'].invalid">
                  <mat-error class="reduce-bottom-space" *ngIf="createSherpaForm.controls['TrainingGoals'].errors?.['minlength']">
                    {{ 'MIN_50_REQUIED' | translate }}
                  </mat-error>
                </mat-error>
              </mat-form-field>
            </div>
            <div>
              <mat-form-field appearance="outline" class="service-field w-full">
                <mat-label>{{ 'FITNESS' | translate }}</mat-label>
                <textarea matInput formControlName="FitnessAchievements" rows="4"></textarea>
                <mat-error class="reduce-bottom-space" *ngIf="createSherpaForm.controls['FitnessAchievements'].invalid">
                  <mat-error class="reduce-bottom-space" *ngIf="createSherpaForm.controls['FitnessAchievements'].errors?.['minlength']">
                    {{ 'MIN_50_REQUIED' | translate }}
                  </mat-error>
                </mat-error>
              </mat-form-field>
            </div>
            <div>
              <mat-form-field appearance="outline" class="service-field w-full">
                <mat-select formControlName="ActivityPreference" placeholder="{{ 'ACTIVITY_PREFERENCE' | translate }}">
                  <mat-option *ngFor="let preference of CATEGORY" [value]="preference">
                    {{ preference }}
                  </mat-option>
                </mat-select>
                <mat-icon matSuffix class="!text-gray-500">keyboard_arrow_down</mat-icon>
              </mat-form-field>
            </div>
            <div>
              <mat-form-field appearance="outline" class="service-field w-full">
                <mat-select formControlName="ActivityExpertise" placeholder="{{ 'ACTIVITY_EXPERIENCE' | translate }}">
                  <mat-option *ngFor="let exp of Experience" [value]="exp">
                    {{ exp }}
                  </mat-option>
                </mat-select>
                <mat-icon matSuffix class="!text-gray-500">keyboard_arrow_down</mat-icon>
              </mat-form-field>
            </div>
          </div>
          <div class="w-full grid grid-cols-1 md:grid-cols-2 gap-x-4"></div>
          <div class="flex items-center">
            <div id="tc-agreements" className="tc-agreements"></div>
          </div>
          <button *ngIf="!isLoading && allChecked" type="submit" class="bg-Primary rounded p-2 mt-2 !w-full">{{ 'SIGN_UP' | translate }}</button>
          <button *ngIf="isLoading || !allChecked" type="submit" class="bg-Primary rounded p-2 mt-2 !w-full" disabled>
            {{ 'SIGN_UP' | translate }}
          </button>
          <p class="text-center text-[#5B5B5B]">
            {{ 'ALREADY_ACCOUNT' | translate }} <a class="font-semibold" routerLink="/">{{ 'LOGIN' | translate }}</a>
          </p>
        </form>
      </div>
      <div *ngIf="signupSuccess" class="w-[500px] flex flex-col items-center">
        <img class="mb-6" src="./assets/image/icons/email100.svg" alt="Go-Sherpa-icon" />
        <p class="text-3xl">{{ 'EMAIL_VERIFY' | translate }}</p>
        <p class="mt-2">
          {{ 'EMAIL_SENT_TO' | translate }} <a class="font-semibold">{{ createSherpaForm.value.Email }}</a>
        </p>
        <p class="">{{ 'EMAIL_SENT_NOTE' | translate }}</p>
        <p class="mt-2">
          {{ 'EMAIL_SENDT_TIP' | translate }} <b>{{ 'SPAM' | translate }}</b
          >.
        </p>
        <button name="form-button" id="form-button" class="btn w-auto px-4 uppercase">{{ 'RESEND_EMAIL' | translate }}</button>
        <p class="text-center text-[#5B5B5B]">
          {{ 'ALREADY_ACCOUNT' | translate }} <a class="font-semibold" routerLink="/">{{ 'LOGIN' | translate }}</a>
        </p>
      </div>
    </div>
    <div name="right-side text-sm" id="right-side" class="flex-1 p-0">
      <div name="section-side-solid-box" id="section-side-solid-box" class="section-side-login-box h-100"></div>
    </div>
  </div>
</div>
