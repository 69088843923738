<div class="signup container-fluid d-flex flex-column align-items-sm-center h-100 p-0 bg-white text-base">
  <div class="row w-100 h-100 m-auto">
    <div name="left-side" id="left-side" class="col-lg-5 p-0 flex justify-center items-center section-box">
      <div class="w-[500px] flex flex-col items-center">
        <img class="mb-6" src="./assets/image/icons/athlete-page-logo.svg" alt="Go-Sherpa-icon" />
        <div class="text-2xl text-black mt-6">Please close this page and log in to the app.</div>
        <div class="flex items-center mt-6">
          <a href="https://apps.apple.com/us/app/go-with-sherpa/id6446352532" target="_blank" class="mr-6">
            <img class="mt-2" src="./assets/image/icons/app-store.svg" alt="apple-app-store-logo">
          </a>
          <a target="_blank">
            <img class="mt-2" src="./assets/image/icons/google-play.svg" alt="google-play-logo">
          </a>
        </div>
        <div class="text-base mt-6">Or Do you want to become a Sherpa?</div>
        <button class="text-gray-900 bg-Primary text-base font-bold rounded px-5 py-3 uppercase mt-6" (click)="becomeToSherpa()">
          Become a Sherpa
        </button>
        <div class="text-gray-400 !mt-4">Sign-in with other account? <button class="text-Primary" (click)="logout()">Login</button></div>
      </div>
    </div>
    <div name="right-side text-sm" id="right-side" class="col-lg-7 d-none d-lg-block p-0">
      <div name="section-side-solid-box" id="section-side-solid-box" class="section-side-login-box h-100"></div>
    </div>
  </div>
</div>
