import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(private readonly http: HttpClient,
              private readonly jwtHelperService: JwtHelperService
            ) { }
            
            isAuthorized(allowedRoles: any[]): boolean {
              // check if the list of allowed roles is empty, if empty, authorize the user to access the page
              if (allowedRoles == null || allowedRoles.length === 0) {
                return true;
              }
          
              // get token from local storage or state management
              const token = localStorage.getItem('auth_token');
              if (token === null) {
                return false;
              } else {
                // decode token to read the payload details
                const decodeToken = this.jwtHelperService.decodeToken(token);
                
          
                // check if it was decoded successfully, if not the token is not valid, deny access
                if (!decodeToken) {
                  return false;
                }
                
                // check if the user roles is in the list of allowed roles, return true if allowed and false if not allowed
                return allowedRoles.includes(decodeToken['role']);
              }
            }
}
