import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { createQuery } from 'src/app/utils/helper';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class ReviewsService {
  constructor(private http: HttpClient) {}

  baseUrl = environment.baseUrl;
  items: any = [];

  public cleanReviews() {
    this.items = [];
  }

  public getReviews(params: any, callback: any) {
    const item = this.items.find((it: any) => _.isEqual(it.params, params));
    if (item) {
      callback(item.data);
    } else {
      this.http.get(this.baseUrl + 'review/v1/getList?' + createQuery(params)).subscribe(data => {
        this.items = _.concat(this.items, { params, data });
        callback(data);
      });
    }
  }

  public changeHiddenReview(id: any, data: any) {
    return this.http.put(this.baseUrl + `review/v1/hidden/${id}`, data);
  }
}
