import { Component } from '@angular/core';
import { CommanService } from './comman.service';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { IconService } from './core/services/icon.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = '';
  online!: Observable<boolean>;

  constructor(
    private readonly commanService: CommanService,
    private readonly toastr: ToastrService,
    private iconService: IconService,
    public translate: TranslateService,
  ) {
    translate.addLangs(['en', 'de']);
    translate.setDefaultLang('en');
    const browserLang: any = translate.getBrowserLang();
    try {
      translate.use(browserLang.match(/en|de/) ? browserLang : 'en');
    } catch (error) {
      console.log(error);
    }
  }

  ngOnInit() {
    this.commanService.title.subscribe(title => {
      this.title = title;
    });
    this.iconService.registerIcons();
  }
  showToaster() {
    this.toastr.error('Please check your internet connection, then try again.');
  }
}
