import { Directive, HostListener } from '@angular/core'

@Directive({
  selector: '[ssnMask]'
})

export class ssnDirective {

  @HostListener('input', ['$event'])

  // Function For Phone Number Pattern ("-")
  onKeyDown(event: KeyboardEvent) {
    const input = event.target as HTMLInputElement;
    let trimmed = input.value.replace(/\s+/g, '').replace(/-/g, '');
  
    trimmed = trimmed.substring(0, 9);
  
    let numbers = [];
    numbers.push(trimmed.substring(0, 3));
  
    if (trimmed.substring(3, 5) !== "") {
      numbers.push(trimmed.substring(3, 5));
    }
  
    if (trimmed.substring(5, 9) !== "") {
      numbers.push(trimmed.substring(5, 9));
    }
  
    input.value = numbers.join('-');
  }
}
