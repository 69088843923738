import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NotificationService } from 'src/app/core/services/notification.service';
import { ToughClicks, TCEventTypes, TCDisplayMethod } from '@toughclicks/js-client-sdk';
import { environment } from 'src/environments/environment';
import { CATEGORY, Experience } from 'src/app/utils/constant';
import jwt_decode from 'jwt-decode';
import { SignupService } from './register-sherpa.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-register-sherpa',
  templateUrl: './register-sherpa.component.html',
  styleUrls: ['./register-sherpa.component.scss'],
})
export class RegisterSherpaComponent implements OnInit {
  createSherpaForm!: FormGroup;
  public submitted = false;
  decodeToken: any;
  avatarBase64: any;
  avatarFile: any;
  signupSuccess = false;
  hidePassword = true;
  hideConfirmPassword = true;
  isLoading = false;
  tc: any = null;
  allChecked: any = false;
  isBecomeToSherpa: any = false;
  Experience: any[] = Experience;
  CATEGORY: any[] = CATEGORY;
  authToken: any = localStorage.getItem('auth_token');
  token: any;
  _id: any;
  decode: any;
  user: any;
  dateDoB: any;
  errorAddress = '';


  constructor(
    private readonly router: Router,
    private mainNotify: NotificationService,
    private readonly signupService: SignupService,
    private activatedRoute: ActivatedRoute,
    private readonly ngxLoader: NgxUiLoaderService,
  ) {
    this.token = this.activatedRoute.snapshot.queryParams['token'];
    if (this.authToken && this.authToken != null) {
      this.decode = jwt_decode(this.authToken);
      this._id = this.decode._id;
    }
  }

  ngOnInit() {
    this.createSherpaForm = new FormGroup({
      FirstName: new FormControl(''),
      DateOfBirth: new FormControl(''),
      Password: new FormControl(''),
      ConfirmPassword: new FormControl(''),
      PhoneNumber: new FormControl(''),
      Address: new FormControl(''),
      Email: new FormControl(''),
      LastName: new FormControl(''),
      ActivityPreference: new FormControl('', [Validators.required]),
      ActivityExpertise: new FormControl('', [Validators.required]),
      AboutMe: new FormControl('', [Validators.required, Validators.minLength(50), Validators.maxLength(3000)]),
      TrainingGoals: new FormControl('', [Validators.required, Validators.minLength(50), Validators.maxLength(3000)]),
      FitnessAchievements: new FormControl('', [Validators.required, Validators.minLength(50), Validators.maxLength(3000)]),
    });

    this.tc = new ToughClicks(environment.client_id, environment.packet_id);

    /******** Configurations ********/
    setTimeout(() => {
      const displayOptions = {
        containerId: 'tc-agreements', //required. Div we should inject UI into.
        signerIdSelector: 'userEmailInput', //optional. Input field we should monitor for signer identifier such as an email input in a sign up form.
        signerId: null, //optional. If you have the signer identifier, you can pass it here.
        displayMethod: TCDisplayMethod.group, //optional. Leave blank to control on Webapp
        shouldOpenModal: false, //optional. Leave blank to control on Webapp
        manualAcceptance: true, //optional. If true, you must call tc.accept() manually.
      };
      const validOptions = this.tc.setDisplayOptions(displayOptions);
      if (validOptions) {
        this.tc.render();
        this.tc.on(TCEventTypes.tcValid, (event: any) => {
          this.allChecked = event.detail.allChecked;
        });
        this.tc.on(TCEventTypes.tcAccepted, () => {});
      }
    }, 1000);
    this.handelGetSherpa();
  }

  handelGetSherpa() {
    this.signupService.getUserDetail(this._id).subscribe((data: any) => {
      this.user = data.data;
      this.dateDoB = data.data.DateOfBirth;
      this.createSherpaForm.patchValue({
        FirstName: data['data']['FirstName'],
        LastName: data['data']['LastName'],
        BirthDate: this.dateDoB,
        PhoneNumber: data['data']['PhoneNumber'],
        Email: data['data']['Email'],
        Address: data['data']['Address'],
        ActivityPreference: data['data']['ActivityPreference'],
        ActivityExpertise: data['data']['ActivityExpertise'],
        AboutMe: data['data']['AboutMe'],
        TrainingGoals: data['data']['TrainingGoals'],
        FitnessAchievements: data['data']['FitnessAchievements'],
      });
    });
  }

  handleCreateUserVerified() {
    if (this.isLoading || !this.allChecked) return;
    if (this.createSherpaForm.invalid) {
      return;
    }
    const payload: any = this.createSherpaForm.value;
    this.ngxLoader.start();
    this.signupService.createUserVerified(this.token, payload).subscribe((result: any) => {
      if (result.code === 200) {
        this.ngxLoader.stop();
        localStorage.removeItem('auth_token');
        localStorage.removeItem('role');
        this.router.navigate(['login']);
        this.mainNotify.showSuccess(result.message, '');
      } else if ([204, 403, 401, 503].includes(result.code)) {
        this.ngxLoader.stop();
        this.mainNotify.showError(result.message, '');
      } else {
        this.mainNotify.showError('Something Went Wrong...', '');
      }
    });
  }

  handleSelectImage(event: any) {
    this.avatarFile = event['target']['files'][0];
    const reader = new FileReader();
    reader.readAsDataURL(this.avatarFile);
    reader.onload = _event => {
      this.avatarBase64 = reader.result;
    };
  }

  checkBecomeToSherpa(event: any) {
    this.isBecomeToSherpa = event.target.checked;
  }

  handelChangeAddress = (address: any) => {
    this.errorAddress = address.length === 0 ? 'Address is required!' : '';
    this.createSherpaForm.controls['Address'].setValue(address);
  };
}
