import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { LIMIT_MESSAGE } from 'src/app/utils/constant';
import * as _ from 'lodash';
import * as moment from 'moment';
import { MessagesService } from './messages.service';
import jwt_decode from 'jwt-decode';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-messages-component',
  templateUrl: './messages.component.html',
})
export class MessagesComponent implements OnInit {
  @Input() conversationId: any;
  @Input() userId: any;
  @Input() userOrderId: any;
  @Input() isVerifiedUser: any;
  @Input() isDisable: any;
  token: any;
  decode: any;
  isLoadMore = true;
  messages: any = [];
  messageCovers: any = [];
  paramsMessage: any = { toDate: moment().toISOString(), limit: LIMIT_MESSAGE };
  isLoading = false;
  searchText = '';
  selectedFiles: FileList | null = null;
  imageUri = '';
  @ViewChild('modalImage', { static: true }) modalImage: TemplateRef<any> | undefined;

  constructor(private modal: NgbModal, private readonly activatedRoute: ActivatedRoute, private messagesService: MessagesService) {}
  ngOnInit() {
    this.paramsMessage = { toDate: moment().toISOString(), limit: LIMIT_MESSAGE };
    this.handleGetMessages('new');
  }

  coverMessages(listMess: any = []) {
    const newMessages = [];
    for (let i = 0; i < listMess.length; i++) {
      const message = listMess[i];
      const messageNext = listMess[i + 1];
      if (!messageNext || moment(message.createdAt).diff(moment(messageNext.createdAt), 'minutes') > 30) {
        newMessages.push({ ...message, showImage: true });
        newMessages.push({ createdAt: message.createdAt });
      } else {
        newMessages.push(message);
      }
    }
    return newMessages;
  }

  checkUser(message: any) {
    return (this.isVerifiedUser && message.VerifiedUserId) || (!this.isVerifiedUser && message.UserId);
  }

  handleSendMessage(files?: FileList) {
    if (!files && _.isEmpty(this.searchText)) return;
    const formData = new FormData();
    if (files) {
      for (let i = 0; i < files.length; i++) {
        formData.append('files[]', files[i]);
      }
    }
    const payload = _.omitBy({ Text: this.searchText, ConversationId: this.conversationId, UserOrderId: this.userOrderId }, elm => !elm);
    Object.keys(payload).forEach(key => {
      formData.append(key, payload[key]);
    });
    this.messagesService.createMessage(formData).subscribe((value: any) => {
      const toDate = value?.data?.[0]?.createdAt;
      this.handleSetParamsMessage(toDate);
      this.handleGetMessages('new');
    });
  }

  handleGetMessages(type: any) {
    if (type === 'top' && !this.isLoadMore) return;
    const params = this.conversationId ? { ConversationId: this.conversationId } : { UserOrderId: this.userOrderId };
    this.isLoading = true;
    this.messagesService.getMessages({ ...params, ...this.paramsMessage }).subscribe((data: any) => {
      const edges = data?.data?.edges || [];
      this.messages = type === 'top' ? _.concat(this.messages, edges) : _.concat(edges, this.messages);
      this.messageCovers = this.coverMessages(this.messages);
      if (type === 'top' && edges.length === 0) {
        this.isLoadMore = false;
      }
      if (type === 'new' && edges.length < LIMIT_MESSAGE) {
        this.isLoadMore = false;
      }
      if (type === 'new') {
        this.searchText = '';
      }
      this.isLoading = false;
    });
  }

  handleSetParamsMessage(toDate: any) {
    const payload: any = { toDate: toDate || moment().toISOString() };
    if (!_.isEmpty(this.messages)) {
      const firstMess: any = _.first(this.messages);
      payload.formDate = moment(firstMess.createdAt).add(1, 'milliseconds').toISOString();
      payload.limit = null;
    } else {
      payload.formDate = null;
      payload.limit = LIMIT_MESSAGE;
    }
    this.paramsMessage = payload;
  }

  onFileChange(event: any) {
    this.handleSendMessage(event.target.files);
  }

  setShowImage(uri: any) {
    this.imageUri = uri;
    this.modal.open(this.modalImage, { size: 'xl' });
  }

  formatTime(time: any) {
    if (!moment().startOf('days').diff(moment(time).startOf('days'), 'minutes')) {
      return moment(time).format('hh:mm A');
    } else if (!moment().startOf('week').diff(moment(time).startOf('week'), 'minutes')) {
      return moment(time).format('ddd, MMM DD, hh:mm A');
    } else {
      return moment(time).format('MMM DD, YYYY, hh:mm A');
    }
  }

  onScroll(event: any) {
    const { scrollHeight, offsetHeight, scrollTop } = event.target;
    if (-scrollTop > (scrollHeight - offsetHeight) * 0.6) {
      const lastMess: any = _.last(this.messages);
      this.paramsMessage = { toDate: moment(lastMess.createdAt).toISOString(), limit: LIMIT_MESSAGE };
      if (!this.isLoading) {
        this.handleGetMessages('top');
      }
    }
  }
}
