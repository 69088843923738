import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Message } from '../utils/envencryption';
@Injectable({
  providedIn: 'root'
})
export class ChangePasswordService {

  constructor(private readonly http:HttpClient) { }

  loginUrl = environment.loginUrl;

  public ChangePassword(data:any) {
    return this.http.put(this.loginUrl+"common/v1/changePassword", data);
  }
}
