import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';

@Component({ selector: 'app-text-lazy', templateUrl: './text-lazy.component.html' })
export class TextLazyComponent implements OnInit {
  @Input() text: any;
  seeMore: any = false;
  showValue: any = '';
  sizeText: any = true;

  constructor() {}

  ngOnInit(): void {
    if (this.text) {
      this.showValue = this.seeMore ? this.text : this.text.substring(0, 150);
      this.sizeText = _.size(this.text) > 200;
    }
  }

  handleSeeMore() {
    this.seeMore = !this.seeMore;
    if (this.text) {
      this.showValue = this.seeMore ? this.text : this.text.substring(0, 150);
    }
  }
}
