import { Component, Input } from '@angular/core';
import { orderStatus } from 'src/app/utils/constant';
import * as _ from 'lodash';
import { getOrderStatus } from 'src/app/utils/helper';

@Component({
  selector: 'app-label-status',
  templateUrl: './label-status.component.html',
})
export class LabelStatusComponent {
  @Input() userOrder: any;
  orderStatus = orderStatus;
  getOrderStatus = getOrderStatus;
  constructor() {}
}
