<div class="flex-1">
  <div class="flex-1 bg-white border border-[#D3D3D3] rounded-lg overflow-hidden">
    <table mat-table [dataSource]="dataSource" matSort aria-describedby="mytable">
      <!-- No Column -->
      <ng-container matColumnDef="_id">
        <th mat-header-cell *matHeaderCellDef id="srNoHeader" class="w-16">{{ 'NO' | translate }}</th>
        <td mat-cell *matCellDef="let element; let i = index">{{ (page - 1) * limit + i + 1 }}</td>
      </ng-container>

      <!-- {{ 'SHERPA_NAME' | translate }} Column -->
      <ng-container matColumnDef="Order">
        <th mat-header-cell *matHeaderCellDef>{{ 'ORDER_NUMBER' | translate }}</th>
        <td mat-cell *matCellDef="let element">{{ element?.UserOrder?.OrderCode }}</td>
      </ng-container>

      <!-- Service Name Column -->
      <ng-container matColumnDef="VerifiedUser">
        <th mat-header-cell *matHeaderCellDef class="hidden md:table-cell">{{ 'SHERPA_NAME' | translate }}</th>
        <td mat-cell *matCellDef="let element" class="hidden md:table-cell">{{ getFullName(element?.VerifiedUser) }}</td>
      </ng-container>

      <!-- Amount Column -->
      <ng-container matColumnDef="Service">
        <th mat-header-cell *matHeaderCellDef class="hidden lg:table-cell">{{ 'SERVICE_NAME' | translate }}</th>
        <td mat-cell *matCellDef="let element" class="hidden lg:table-cell">{{ element?.Service?.Name }}</td>
      </ng-container>

      <!-- Amount Column -->
      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef class="hidden lg:table-cell">{{ 'DATE' | translate }}</th>
        <td mat-cell *matCellDef="let element" class="hidden lg:table-cell">{{ formatTime(element?.createdAt) }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns" class="bg-[#E2E2E2]"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        class="hover:bg-BackgroundHover cursor-pointer"
        (click)="setShowModalMessage(row)"
      ></tr>
    </table>
    <div class="bg-white w-full h-72 flex flex-col items-center justify-center" *ngIf="dataSource.length === 0">
      <mat-icon svgIcon="messages60" class="!h-[60px] !w-[60px]"></mat-icon>
      <p class="text-gray-500 text-xl font-bold m-0 mt-3">{{ 'NO_CONV' | translate }}</p>
      <p class="text-gray-500 text-base m-0 mt-1">{{ 'CONV_NOTE' | translate }}</p>
    </div>
  </div>

  <div class="ngb-pagination" *ngIf="dataSource.length > 0">
    <ngb-pagination
      #paginator
      id="paginator"
      [collectionSize]="total"
      [pageSize]="limit"
      [maxSize]="2"
      [rotate]="true"
      [(page)]="page"
      [boundaryLinks]="true"
      (pageChange)="handlePageChange($event)"
      class="d-flex justify-content-end pagination-rounded-flat pagination-success custom-pagination ms-4"
    >
      <ng-template ngbPaginationPrevious>
        <span>{{ 'PREVIOUS' | translate }}</span>
      </ng-template>
      <ng-template ngbPaginationNext>
        <span>{{ 'NEXT' | translate }}</span>
      </ng-template>
      <ng-template ngbPaginationFirst>
        <span>{{ 'FIRST' | translate }}</span>
      </ng-template>
      <ng-template ngbPaginationLast>
        <span>{{ 'LAST' | translate }}</span>
      </ng-template>
    </ngb-pagination>
  </div>
</div>
<div class="relative">
  <ng-template #modalMessage let-close="close">
    <div class="flex items-center justify-center relative">
      <div class="absolute flex items-center right-2 top-2">
        <button type="button" class="p-2.5" (click)="close()">
          <mat-icon svgIcon="closeIcon" class="!w-[12px] !h-[12px]"></mat-icon>
        </button>
      </div>
      <div class="flex flex-1 h-[550px]">
        <app-messages-component [conversationId]="conversationId" [isDisable]="true" [isVerifiedUser]="!!verifiedUserId" class="flex flex-1"></app-messages-component>
      </div>
    </div>
  </ng-template>
</div>
