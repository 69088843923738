import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { Icons } from 'src/app/utils/constant';

@Injectable({
  providedIn: 'root',
})
export class IconService {

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
  ) { }

  public registerIcons(): void {
    this.loadIcons(Object.entries(Icons), '../../../assets/image/icons');
  }

  private loadIcons(icons: any[], iconUrl: string): void {
    icons.forEach(icon => {
      this.matIconRegistry.addSvgIcon(icon[0], this.domSanitizer.bypassSecurityTrustResourceUrl(`${iconUrl}/${icon[1]}.svg`));
    });
  }
}
