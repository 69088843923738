import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SignupService {
  token: any;
  decode: any;
  id: any = '';

  constructor(private readonly http: HttpClient) {}

  loginUrl = environment.loginUrl;

  public login(loginData: any) {
    return this.http.post(this.loginUrl + 'common/v1/login', loginData);
  }

  public getUserDetail(_id: any) {
    return this.http.get(this.loginUrl + 'user/v1/getDetails/'+_id);
  }

  public createUserVerified(token: any, payload: any) {
    return this.http.put(this.loginUrl + 'verifiedUser/v1/create?token=' + token, payload);
  }
}
