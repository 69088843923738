import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { createQuery } from 'src/app/utils/helper';
@Injectable({
  providedIn: 'root',
})
export class MessagesService {
  constructor(private http: HttpClient) {}

  baseUrl = environment.baseUrl;

  public getMessages(query: any) {
    return this.http.get(this.baseUrl + 'messages/v1/getMessages?' + createQuery(query));
  }

  public createMessage(data: any) {
    return this.http.post(this.baseUrl + 'messages/v1/createMessage', data);
  }
}
