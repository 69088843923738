import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ResetPasswordService } from './reset-password.service';
import { ActivatedRoute } from '@angular/router';
import Validation from '../utils/validation';
import { NotificationService } from '../core/services/notification.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html'
})
export class ResetPasswordComponent implements OnInit {

  resetForm!: FormGroup;
  hidePassword = true;
  hideResetPassword = true;
  public submitted:boolean = false;
  queryToken: any;


  constructor(private router: Router,
              private resetPasswordService : ResetPasswordService,
              private mainNotify : NotificationService,
              private activatedRoute: ActivatedRoute
            ) { 
    this.queryToken = this.activatedRoute.snapshot.queryParams['token']

            }

  ngOnInit(): void {
    
    this.Checkquerytoken();

    this.resetForm = new FormGroup({
      NewPassword: new FormControl('', [Validators.required, Validators.minLength(8), Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')]),
      ConfirmPassword: new FormControl('', [Validators.required, Validators.minLength(8), Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')])
    },
    {
      validators: [Validation.match('NewPassword', 'ConfirmPassword')]
    });
  }

  HandleResetPasswordSubmit() {
    this.submitted = true;
    if (this.resetForm.invalid) {
      return;
    }
    
    let data ={ "NewPassword" : this.resetForm.value.NewPassword,
                "ConfirmPassword" :this.resetForm.value.ConfirmPassword
              }
    
    
    this.resetPasswordService.ResetPassword(this.queryToken,data).subscribe((result:any)=>  {
      
      if(result.code == 200){
        this.mainNotify.showSuccess(result.message,'');
        this.router.navigate(['/login']);
      }
      else if(result.code == 400){
        this.mainNotify.showError(result.message,'');
      }
      else if(result.code == 403){
        this.mainNotify.showError(result.message,'');
      }
      else {
        this.mainNotify.showError('Something Went Wrong...','');
      }
    });
  }

  Checkquerytoken() {
    this.resetPasswordService.CheckResetToken(this.queryToken).subscribe((result: any) => {
      if (result.code == 200) {
      }
      else if(result.code == 401){
        this.mainNotify.showError(result.message,'');
      }
      else {
        this.router.navigate(['/']);
      }
    })
  }

}
