import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ReviewsService } from './reviews.service';
import { DatePipe } from '@angular/common';
import * as _ from 'lodash';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { reviewType } from 'src/app/utils/constant';
import { formatTimeRequest } from 'src/app/utils/helper';

@Component({
  selector: 'app-reviews',
  templateUrl: './reviews.component.html',
  providers: [DatePipe],
})
export class ReviewsComponent implements OnInit {
  @Input() userId: any;
  @Input() verifiedUserId: any;
  @Input() isTable: boolean = false;
  @Input() limit: any = 10;
  page = 1;
  total = 0;
  dataSource: any = [];
  conversationId: any;
  displayedColumns: string[] = ['_id', 'action'];
  formatTimeRequest = formatTimeRequest;
  constructor(private modal: NgbModal, private reviewManagement: ReviewsService) {}

  ngOnInit(): void {
    this.getReviews();
  }

  setShowModalMessage(conversation: any) {
    this.conversationId = conversation._id;
  }

  getFullName(user: any) {
    return _.trim(`${user?.FirstName || ''} ${user?.LastName || ''}`);
  }

  formatTime(time: any) {
    return moment(time).format('YYYY-MM-DD');
  }

  handlePageChange(value: any) {
    this.page = value;
    this.getReviews();
  }

  handleChangeHidden(item: any) {
    this.reviewManagement.changeHiddenReview(item._id, { isHidden: !item.isHidden }).subscribe((res: any) => {
      if (res.code === 200) {
        this.reviewManagement.cleanReviews();
        this.getReviews();
      }
    });
  }

  getUser(review: any) {
    return review[this.verifiedUserId ? 'User' : 'VerifiedUser'];
  }

  getReviews() {
    const type = this.userId ? reviewType.SHERPA : reviewType.ATHLETE;
    const params = { skip: (this.page - 1) * this.limit, limit: this.limit, userId: this.userId, verifiedUserId: this.verifiedUserId, type };
    this.reviewManagement.getReviews(params, (response: any) => {
      if (response.data) {
        this.dataSource = response?.data?.edges || [];
        this.total = response?.data?.pageInfo?.[0]?.count || 0;
      }
    });
  }
}
