<div class="container-fluid d-flex flex-column align-items-sm-center h-100 p-0">
    <div class="row w-100 h-100 m-auto">
      <div name="left-side" id="left-side" class="col-lg-5 p-0">
        <div name="section-box" id="section-box" class="section-box forgotpswd-color">
          <section name="section-content" id="section-content" class="section-content">
            <form name="login-form" id="login-form" class="form form-box" [formGroup]="forgotPasswordForm" (ngSubmit)="HandleForgotClick()" autocomplete="none">
              <div class="section-left-header">
                <img class="logo-sherpa" src="./assets/image/Temp.png" alt="Go-Sherpa" />
              </div>
              <div name="form-header" id="form-header" class="text-left form-header">
                  <h1 class="form-title">{{ 'FORGOT_PASSWORD' | translate }}</h1>
                  <p class="form-subtitle subtitle-forgotpswd-color">{{ 'FORGOT_PASSWORD_NOTE' | translate }}</p>
              </div>
              <div name="form-content" id="form-content" class="form-content">
                <mat-form-field hideRequiredMarker appearance="outline" name="mat-form-field-email" id="mat-form-field-email">
                  <input matInput required type="text"  class="form-field" name="Email" id="Email" placeholder="Email Address" formControlName="Email" autocomplete="none" />
                  <mat-error class="reduce-bottom-space" *ngIf="forgotPasswordForm.controls['Email'].invalid">
                    <mat-error class="reduce-bottom-space" *ngIf="forgotPasswordForm.controls['Email'].errors?.['required']">{{ 'EMAIL_REQUIRED' | translate  }}</mat-error>
                    <mat-error class="reduce-bottom-space" *ngIf="forgotPasswordForm.controls['Email'].errors?.['email'] || forgotPasswordForm.controls['Email'].errors?.['pattern']">{{ 'EMAIL_INVALID' | translate }}</mat-error>
                    <mat-error class="reduce-bottom-space" *ngIf="forgotPasswordForm.controls['Email'].errors?.['maxlength']">{{ 'EMAIL_MUST_CHAR' | translate }}</mat-error>
                  </mat-error>
                </mat-form-field>
              </div>
              <div name="form-footer" id="form-footer" class="form-footer">
                <button type="submit" name="form-button" id="form-button" class="btn">
                  SUBMIT
                </button>
                <p class="form-signin-link" routerLink="">{{ 'BACK_TO' | translate }} <a class="form-signin-link login">{{ 'LOGIN' | translate }}</a></p>
              </div>
            </form>
          </section>
        </div>
      </div>
      <div name="right-side" id="right-side" class="col-lg-7 d-none d-lg-block p-0">
        <div name="section-side-solid-box" id="section-side-solid-box" class="section-side-forgotpswd-box h-100">
        </div>
      </div>
    </div>
  </div>